import { z } from "zod";

const translationPath = "components.nonTasyRegister.errors.";

const mediaTypeSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().nullish(),
});

const mediaFileSchema = z.object({
  mediaType: mediaTypeSchema.optional(),
  id: z.string(),
  mediaTypeId: z.string().optional(),
  isPublic: z.boolean().optional(),
  fileName: z.string().optional(),
  cdnDomain: z.string().optional(),
  filePath: z.string().optional(),
  mimeType: z.string().optional(),
  fileSize: z.number().optional(),
  file: z.instanceof(File).optional(),
});

export const formNonTasySchema = z
  .object({
    name: z.string().min(1, `${translationPath}name`),
    whatsappNumber: z.string().min(1, `${translationPath}whatsapp_number`),
    frontDoc: mediaFileSchema.nullish(),
    frontDocPhoto: z.string().nullish(),
    backDoc: mediaFileSchema.nullish(),
    backDocPhoto: z.string().nullish(),
    selfiePhoto: z.string().nullish(),
    selfieDocPhoto: z.string().nullish(),
    photos: z.boolean().default(false),
  })
  .superRefine((val, ctx) => {
    const hasPhotos =
      (val.frontDocPhoto || val.frontDoc) &&
      (val.backDocPhoto || val.backDoc) &&
      val.selfiePhoto &&
      val.selfieDocPhoto;

    if (!hasPhotos) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `${translationPath}photos`,
        path: ["photos"],
      });
    }
  });

export type TFormNonTasy = z.infer<typeof formNonTasySchema>;
