import { Box, useMediaQuery, useTheme } from "@mui/material";
import { memo } from "react";
import { IMasksProps } from "./models";

export const Masks = memo(({ type }: IMasksProps) => {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  switch (type) {
    case "front-doc":
    case "back-doc":
      return (
        <Box
          sx={(t) => ({
            mb: matches ? 5 : undefined,
            width: matches ? 250 : 300,
            height: matches ? 200 : 200,
            border: `3px dashed ${t.palette.primary.main}`,
          })}
        />
      );

    case "self-doc":
      return (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Box
            sx={(t) => ({
              width: matches ? 150 : 180,
              height: matches ? 100 : 110,
              border: `3px dashed ${t.palette.primary.main}`,
              position: "absolute",
              bottom: 60,
              right: matches ? undefined : 40,
            })}
          />
          <Box
            sx={(t) => ({
              mb: 5,
              width: matches ? 140 : 200,
              height: matches ? 200 : 260,
              border: `3px dashed ${t.palette.primary.main}`,
              borderRadius: "100%",
              position: "absolute",
              top: 10,
            })}
          />
        </Box>
      );

    default:
      return (
        <Box
          sx={(t) => ({
            mb: 5,
            width: matches ? 220 : 220,
            height: matches ? 280 : 300,
            border: `3px dashed ${t.palette.primary.main}`,
            borderRadius: "100%",
          })}
        />
      );
  }
});
