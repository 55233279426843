import { useMediaQuery, useTheme } from "@mui/material";
import { memo } from "react";
import { IllustrationSection } from "./IllustrationSection/IllustrationSection";
import { NonTasyRegister } from "./NonTasyRegister/NonTasyRegister";
import { NonTasyRegisterContainer } from "./NonTasyRegisterContainer/NonTasyRegisterContainer";

export const NonTasyRegisterForm = memo(() => {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <NonTasyRegisterContainer>
      {matches && <IllustrationSection />}
      <NonTasyRegister />
    </NonTasyRegisterContainer>
  );
});
