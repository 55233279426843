import {useMediaQuery, useTheme} from "@mui/material";
import { memo } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../../../store/store";

export const Header = memo(() => {
  const { logo, logo_dark, mode, name } = useAppSelector(
    (state) => state.theme
  );

  const portalLogo = mode === "1" ? logo : logo_dark ? logo_dark : logo;

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Link
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textDecoration: "none",
        color: theme.palette.primary.main,
      }}
      to="/"
    >
      <img src={portalLogo} alt={name} style={{ width: matches ? "60%" : "70%" }} />
    </Link>
  );
});
