import {
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface IRadio<T extends any> {
  label: string;
  value: T;
}

interface IRadioGoupInputProps<T extends any> {
  name: string;
  groupLabel: string;
  radios: IRadio<T>[];
  disableBorder?: boolean;
  inline?: boolean;
  fullwidth?: boolean;
  valueField?: keyof T;
  disabled?: boolean;
}

const RadioGroupInput = <T extends any>({
  name,
  radios,
  groupLabel,
  disableBorder = false,
  valueField,
  inline = false,
  fullwidth = false,
  disabled = false,
}: IRadioGoupInputProps<T>) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      render={({ fieldState: { error }, field }) => {
        const { onChange, value, ref } = field;
        return (
          <Box
            sx={(theme) => ({
              paddingBlock: disableBorder ? 0 : 1,
              paddingInline: disableBorder ? 0 : 2,
              border: disableBorder
                ? undefined
                : theme.palette.mode === "light"
                ? `1px solid ${theme.palette.grey[500]}`
                : `1px solid ${theme.palette.grey[400]}`,
              borderRadius: 2,
            })}
          >
            <FormLabel>{groupLabel}</FormLabel>
            <RadioGroup>
              <Stack
                sx={{
                  width: fullwidth ? "100%" : undefined,
                  display: "flex",
                  flexDirection: inline ? "row" : undefined,
                  justifyContent: fullwidth ? "space-between" : undefined,
                  flexWrap: "wrap",
                }}
              >
                {radios.map((radio) => {
                  return (
                    <FormControlLabel
                      key={radio.label}
                      value={radio.value}
                      disabled={disabled}
                      control={
                        <Radio
                          size="small"
                          checked={
                            value
                              ? valueField
                                ? value[valueField] === radio.value[valueField]
                                : value === radio.value
                              : undefined
                          }
                          onChange={() => {
                            onChange(radio.value);
                          }}
                        />
                      }
                      label={radio.label}
                      ref={ref}
                    />
                  );
                })}
              </Stack>
              <Typography
                variant="body2"
                color={(theme) => theme.palette.error.main}
              >
                {error ? t(error?.message || "") : ""}
              </Typography>
            </RadioGroup>
          </Box>
        );
      }}
      name={name}
      control={control}
    />
  );
};

export default RadioGroupInput;
