import { Box } from "@mui/material";
import { motion } from "framer-motion";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useRegisterContext } from "../../../../../store/contexts/RegisterContext/RegisterContext";
import { BackDocIllustration } from "../../../../illustrations/BackDocIllustration";
import { FrontDocIllustration } from "../../../../illustrations/FrontDocIllustration";
import { SelfieForDocIllustration } from "../../../../illustrations/SelfieForDocIllustration";
import { SelfieWithDocIllustration } from "../../../../illustrations/SelfieWithDocIllustration";
import { SelfieIllustration } from "../../../../illustrations/SelfIllustration";
import { Illustration } from "./Illustration/Illustration";
import { IIllustrationSectionProps } from "./models";

const translationPath = "components.nonTasyRegister.helpers.";

export const IllustrationSection = memo(
  ({ width }: IIllustrationSectionProps) => {
    const { t } = useTranslation();

    const { illustrationRegister } = useRegisterContext();

    const RenderIllustration = useMemo(() => {
      switch (illustrationRegister) {
        case "front-doc":
          return (
            <Illustration
              title={t(`${translationPath}frontDoc.title`)}
              subtitle={t(`${translationPath}frontDoc.subtitle`)}
              illustration={FrontDocIllustration}
            />
          );
        case "back-doc":
          return (
            <Illustration
              title={t(`${translationPath}backDoc.title`)}
              subtitle={t(`${translationPath}backDoc.subtitle`)}
              illustration={BackDocIllustration}
            />
          );
        case "self":
          return (
            <Illustration
              title={t(`${translationPath}selfie.title`)}
              subtitle={t(`${translationPath}selfie.subtitle`)}
              illustration={SelfieForDocIllustration}
            />
          );
        case "self-doc":
          return (
            <Illustration
              title={t(`${translationPath}selfieDoc.title`)}
              subtitle={t(`${translationPath}selfieDoc.subtitle`)}
              illustration={SelfieWithDocIllustration}
            />
          );
        case "default":
        default:
          return (
            <SelfieIllustration
              style={{
                height: "80%",
              }}
            />
          );
      }
    }, [illustrationRegister, t]);

    return (
      <Box
        sx={(t) => ({
          py: 2,
          width: width ?? "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: t.palette.primary.main,
          borderRadius: t.shape.borderRadius,
        })}
      >
        <motion.div
          key={illustrationRegister}
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {RenderIllustration}
        </motion.div>
      </Box>
    );
  }
);
