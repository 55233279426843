import axios, { AxiosResponse, isAxiosError } from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import i18n from "../i18n";
import { getDomain } from "../services/domain.service";
import { tokenService } from "../services/token.service";
import { tokenTemporaryService } from "../services/tokenTemorary.service";
import { notificationSliceActions } from "../store/slices/notification";
import { FILE_NAME_VALIDATOR } from "../utils/Validators/regexValidators";
import { useMimeType } from "./useMimeType";

const useDownload = () => {
  const dispatch = useDispatch();
  const loggedToken = tokenService.getAccessToken();
  const temporaryToken = tokenTemporaryService.getTemporaryAccessToken();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const { detectMimeType } = useMimeType();

  const token = loggedToken || temporaryToken;

  const downloadFile = (
    url: string,
    id: string,
    fileName: string,
    item?: any
  ) => {
    setLoading(true);
    axios
      .get(url + id, {
        responseType: "blob", // Define o tipo de resposta como um blob (objeto binário)
        params: item,
        headers: {
          Authorization: `Bearer ${token}`,
          Domain: getDomain(),
          "Accept-Language": i18n.language,
        },
      })
      .then((response) => {
        // Lida com a resposta da API
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);

        // Cria um link temporário e simula o clique para iniciar o download
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        if (isAxiosError(err)) {
          if (err.response?.data.errors) {
            let errorMessage = "";

            Object.keys(err.response?.data.errors).forEach((key) => {
              errorMessage += err.response?.data.errors[key].join(" | ") + " ";
            });

            dispatch(
              notificationSliceActions.showNotification({
                message: errorMessage,
                type: "error",
              })
            );
          }
          if (err.response?.data.detail) {
            dispatch(
              notificationSliceActions.showNotification({
                message: err.response?.data.detail || err.message,
                type: "error",
              })
            );
          }
        } else {
          dispatch(
            notificationSliceActions.showNotification({
              message: err.message,
              type: "error",
            })
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const downloadFileParams = (url: string, item: any, fileName?: string) => {
    setLoading(true);
    setError(undefined);
    axios
      .get(url, {
        params: item,
        responseType: "blob", // Define o tipo de resposta como um blob (objeto binário)
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": i18n.language,
          Domain: getDomain(),
        },
      })
      .then((response: AxiosResponse) => {
        // Lida com a resposta da API
        const file = new Blob([response.data], {
          type: "application/pdf",
        });
        // const file = new Blob([response.data]);
        const fileURL = URL.createObjectURL(file);

        // Cria um link temporário e simula o clique para iniciar o download
        const link = document.createElement("a");
        link.href = fileURL;

        if (fileName) {
          link.setAttribute("download", fileName);
        } else {
          let newFileName = "";
          const contentDisposition = response.headers["content-disposition"];

          if (contentDisposition) {
            const match = contentDisposition.match(FILE_NAME_VALIDATOR);
            if (match && match.length === 2) {
              newFileName = match[1];
            }
          }

          link.setAttribute("download", newFileName);
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        if (isAxiosError(err)) {
          if (err.response?.status === 404) {
            setError(err.response.data);
          }
          if (err.response?.data.errors) {
            let errorMessage = "";

            Object.keys(err.response?.data.errors).forEach((key) => {
              errorMessage += err.response?.data.errors[key].join(" | ") + " ";
            });

            dispatch(
              notificationSliceActions.showNotification({
                message: errorMessage,
                type: "error",
              })
            );
          }
          if (err.response?.data.detail) {
            dispatch(
              notificationSliceActions.showNotification({
                message: err.response?.data.detail || err.message,
                type: "error",
              })
            );
          }
        } else {
          dispatch(
            notificationSliceActions.showNotification({
              message: err.message,
              type: "error",
            })
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const downloadBase64File = (base64Data: string, filename: string): void => {
    const mimeType = detectMimeType(base64Data);

    if (!mimeType) return;

    const link = document.createElement("a");

    link.href = `data:${mimeType};base64,${base64Data}`;

    link.download = filename;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  return {
    downloadFile,
    downloadFileParams,
    downloadBase64File,
    loading,
    error,
  };
};

export default useDownload;
