import { memo } from "react";
import { FormStepperContextProvider } from "../../../store/contexts/FormStepperContext";
import { FormSteps } from "./FormSteps/FormSteps";
import { IFormStepper } from "./models";

export const FormsStepper = memo(
  ({ initialStep, currentActiveStep, steps, endComponent }: IFormStepper) => {
    return (
      <FormStepperContextProvider
        initialStep={initialStep}
        currentActiveStep={currentActiveStep}
      >
        <FormSteps endComponent={endComponent} steps={steps} />
      </FormStepperContextProvider>
    );
  }
);
