export const FrontDocIllustration = (props: any) => {
  return (
    <svg
      width={265}
      height={200}
      viewBox="0 0 265 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M236.731 195.377H27.428C13.385 195.377 2 183.992 2 169.949V29.427C2 15.384 13.385 4 27.428 4h209.303c14.044 0 25.429 11.385 25.429 25.427V169.95c0 14.043-11.385 25.428-25.429 25.428z"
        fill="#D7E3F9"
      />
      <path
        d="M104.259 172.527H33.836a8.2 8.2 0 01-8.199-8.199V73.242a8.199 8.199 0 018.199-8.199h70.423a8.198 8.198 0 018.199 8.2v91.085a8.199 8.199 0 01-8.199 8.199z"
        fill="#EDF0F2"
      />
      <path
        d="M210.889 124.939h-71.002a5.554 5.554 0 01-5.554-5.555 5.554 5.554 0 015.554-5.555h71.002a5.555 5.555 0 110 11.11zm0 21.274h-71.002a5.554 5.554 0 010-11.109h71.002a5.555 5.555 0 110 11.109zm-30.126 21.275h-40.875a5.554 5.554 0 01-5.554-5.555 5.554 5.554 0 015.554-5.555h40.875a5.555 5.555 0 110 11.11z"
        fill="#448CCA"
      />
      <mask
        id="a"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={33}
        y={77}
        width={72}
        height={96}
      >
        <path d="M104.374 77.76H33.722v94.768h70.652V77.76z" fill="#fff" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M98.082 183.271H41.134a4.334 4.334 0 01-4.336-4.335v-22.858c0-8.852 7.177-16.029 16.03-16.029h33.56c8.853 0 16.03 7.177 16.03 16.029v22.858a4.335 4.335 0 01-4.336 4.335z"
          fill="#4D5964"
        />
        <path
          d="M52.937 143.194l12.584 32.56c1.444 3.737 6.73 3.737 8.174 0l12.585-32.56a2.311 2.311 0 00-2.157-3.145h-29.03a2.312 2.312 0 00-2.156 3.145z"
          fill="#D7E3F9"
        />
        <path
          d="M60.336 145.072l9.272 9.272 9.273-9.272c1.544-1.545.45-4.186-1.733-4.186H62.069c-2.184 0-3.279 2.64-1.733 4.186z"
          fill="#CE9846"
        />
        <path
          d="M69.608 184.007a4.776 4.776 0 01-4.776-4.776v-22.598a4.776 4.776 0 119.553 0v22.598a4.776 4.776 0 01-4.777 4.776z"
          fill="#CE9846"
        />
        <path
          d="M80.26 140.886H58.956c-.809 0-1.464-.656-1.464-1.465v-4.67c0-.81.656-1.465 1.464-1.465H80.26c.809 0 1.464.656 1.464 1.465v4.67c0 .809-.655 1.465-1.464 1.465z"
          fill="#D7E3F9"
        />
        <path
          d="M77.891 117.716H61.324v25.803h16.567v-25.803z"
          fill="#F39912"
        />
        <path
          d="M71.118 145.427l7.067 7.066c1.306 1.306 3.539.381 3.539-1.466V134.82l-10.606 10.607zm-3.02 0l-7.067 7.066c-1.306 1.306-3.54.381-3.54-1.466V134.82l10.607 10.607z"
          fill="#EDF0F2"
        />
        <path
          d="M70.927 145.618l4.148-4.148H64.141l4.148 4.148c.729.728 1.91.728 2.638 0zM88.483 119.51a5.36 5.36 0 10.001-10.718 5.36 5.36 0 000 10.718z"
          fill="#F39912"
        />
        <path
          d="M91.406 114.151a2.922 2.922 0 11-5.845 0 2.922 2.922 0 015.845 0zm-45.723 0a5.36 5.36 0 1010.72-.002 5.36 5.36 0 00-10.72.002z"
          fill="#F39912"
        />
        <path
          d="M48.121 114.151a2.922 2.922 0 105.845 0 2.922 2.922 0 00-5.845 0z"
          fill="#F39912"
        />
        <path
          d="M69.608 138.298c-10.245 0-18.55-8.305-18.55-18.55v-13.974c0-10.246 8.306-18.55 18.55-18.55 10.246 0 18.55 8.305 18.55 18.55v13.974c0 10.245-8.305 18.55-18.55 18.55z"
          fill="#FAD090"
        />
        <path
          d="M87.598 112.759a2.767 2.767 0 01-2.767-2.767V93.244a2.767 2.767 0 015.534 0v16.748a2.767 2.767 0 01-2.767 2.767zm-35.98 0a2.767 2.767 0 002.767-2.767V93.244a2.767 2.767 0 00-5.534 0v16.748a2.767 2.767 0 002.767 2.767z"
          fill="#657684"
        />
      </g>
      <mask
        id="b"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={33}
        y={77}
        width={72}
        height={96}
      >
        <path d="M104.374 77.76H33.722v94.768h70.652V77.76z" fill="#fff" />
      </mask>
      <g mask="url(#b)">
        <path
          d="M48.9 92.733c0-6.264 5.077-11.341 11.34-11.341h13.25c6.263 0 11.34 5.078 11.34 11.34 0 6.264-5.077 11.342-11.34 11.342H60.24c-6.263 0-11.34-5.078-11.34-11.341z"
          fill="#657684"
        />
      </g>
      <mask
        id="c"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={33}
        y={77}
        width={72}
        height={96}
      >
        <path d="M104.374 77.76H33.722v94.768h70.652V77.76z" fill="#fff" />
      </mask>
      <g mask="url(#c)">
        <path
          d="M78.773 91.5a6.355 6.355 0 006.327-6.944c-.103-1.092.894-1.982 1.93-1.618 4.233 1.487 7.269 5.519 7.269 10.26 0 6.007-4.869 10.876-10.875 10.876H65.125a6.288 6.288 0 010-12.575h13.648z"
          fill="#657684"
        />
      </g>
      <mask
        id="d"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={33}
        y={77}
        width={72}
        height={96}
      >
        <path d="M104.374 77.76H33.722v94.768h70.652V77.76z" fill="#fff" />
      </mask>
      <g mask="url(#d)">
        <path
          d="M69.279 87.542a6.355 6.355 0 006.327-6.944c-.103-1.092.894-1.982 1.93-1.618 4.233 1.487 7.269 5.519 7.269 10.26 0 6.007-4.869 10.876-10.875 10.876H60.982a6.288 6.288 0 010-12.575h8.297z"
          fill="#657684"
        />
      </g>
      <path
        d="M236.731 4H27.428C13.385 4 2 15.385 2 29.428V40.11a5.765 5.765 0 005.765 5.765h248.629a5.765 5.765 0 005.765-5.765V29.427C262.16 15.384 250.775 4 236.731 4z"
        fill="#448CCA"
      />
    </svg>
  );
};
