export const SelfieWithDocIllustration = (props: any) => {
  return (
    <svg width={360} height={360} xmlns="http://www.w3.org/2000/svg" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 750 750"
        width={360}
        height={360}
        {...props}
      >
        <path
          fill="#cce7ff"
          d="M162.1 604.2L147 559c-1.7-4.9 2.6-10 8.8-10h26.6c2.8 0 5.2-1.7 5.6-4l7.8-51c.6-3.9 4.5-6.8 9-6.8h45.5c2.7 0 5-1.5 5.5-3.7l8.2-32.8c.9-3.6 4.6-6.2 8.9-6.2h12.5c5.2 0 9.7 3.9 9 8.8l-11.8 83.3c-.3 2.3 2 4.1 4.6 4.1h89.7c5 0 9.1 3.5 9.1 7.8v23.2c0 1.6 1.6 2.9 3.6 2.9h21.1c2 0 3.6-1.3 3.6-2.9v-10.2c0-1.7.7-3.4 1.9-4.8 5.1-4.8 6.8-10.1 13.6-10.1H507v-22.7c0-4.3 4.1-7.8 9.1-7.8h29.3c5 0 9.1 3.5 9.1 7.8v38.4c0 3.2 3.2 5.8 7.1 5.8h10c3.9 0 7.1-2.6 7.1-5.8v-2c0-4.3 4.1-7.8 9.1-7.8h8.4c5 0 9.1 3.5 9.1 7.8v43.6c0 .6-.4 1-1 1s-1-.4-1-1v-43.6c0-3.2-3.2-5.8-7.1-5.8h-8.4c-3.9 0-7.1 2.6-7.1 5.8v2c0 4.3-4.1 7.8-9.1 7.8h-10c-5 0-9.1-3.5-9.1-7.8v-38.4c0-3.2-3.2-5.8-7.1-5.8h-29.3c-3.9 0-7.1 2.6-7.1 5.8v23.7c0 .6-.4 1-1 1h-78.3c-5.6 0-6.9 4.4-12.1 9.5-.9 1-1.4 2.2-1.4 3.5v10.2c0 2.7-2.5 4.9-5.6 4.9h-21.1c-3.1 0-5.6-2.2-5.6-4.9v-23.2c0-3.2-3.2-5.8-7.1-5.8h-89.7c-3.9 0-7.1-2.9-6.6-6.4l11.8-83.3c.5-3.7-3.2-6.5-7.1-6.5h-12.5c-3.4 0-6.3 2-7 4.7l-8.2 32.8c-.8 3-3.8 5.2-7.4 5.2h-45.5c-3.6 0-6.6 2.2-7.1 5.1l-7.8 51c-.5 3.3-3.7 5.7-7.5 5.7h-26.6c-4.5 0-8.2 3.5-6.9 7.4l15.1 45.2c.2.5-.1 1.1-.6 1.3-.4 0-1-.2-1.1-.8z"
          className="colorccd2ff svgShape"
        />
        <path
          fill="#fcd9a4"
          d="M351 205.1s-20.2-7.3-22.2-3.3c-1.7 3.5 5.4 6.2 10.4 8.1-2.5-.4-12.2-4.3-13.3-1.1-1.4 4.1 6.3 6.1 13.3 8.4-1.8-.2-12.1-3.7-13.3-.4-1.6 4.1 9.5 7.3 15.9 9.3-1.2 0-10.9-3.5-11.9-.5-1.9 5.7 31.9 16.5 34.2 13 9.6-14.8 15.8-19.3 3.3-33.2"
          className="colorfcbba4 svgShape"
        />
        <path
          fill="#bbb6b6"
          d="M444.4 587.3c1.8 6.6 3.4 13.8 4.4 20.3.9 6.1 1.2 11.7.4 15.5-.9 4.4-4.8 7.5-9.3 7.4-18.4-.4-65.1-2.5-65.7-7.7-1-8.8 11.5-14.5 20.2-17.6 4.1-1.5 7.4-4.3 9.6-7.9 1.2-2 2-4.2 2.4-6.6l38-3.4z"
          className="colorffc25e svgShape"
        />
        <path
          fill="#a7a9a9"
          d="M444.4 587.3c1.8 6.6 3.4 13.8 4.4 20.3-14.6 7.1-34.2-3.4-44.7-10.4 1.2-2 2-4.2 2.4-6.6l37.9-3.3z"
          className="colorefa130 svgShape"
        />
        <path
          fill="#112c44"
          d="M425.2 625.3c-22.8 0-44.4-5.2-44.7-5.3-.5-.1-.9-.7-.7-1.2.1-.5.7-.9 1.2-.7.4.1 36 8.7 63.8 3.6.5-.1 1.1.3 1.2.8.1.5-.3 1.1-.8 1.2-6.5 1.2-13.3 1.6-20 1.6zM399.4 606l-3.5-3.2c-.4-.4-.4-1-.1-1.4s1-.4 1.4-.1l3.5 3.2c.4.4.4 1 .1 1.4-.3.5-1 .5-1.4.1zm-3.6 2.6l-3.5-3.2c-.4-.4-.4-1-.1-1.4.4-.4 1-.4 1.4-.1l3.5 3.2c.4.4.4 1 .1 1.4-.4.5-1 .5-1.4.1zm7.2-5.2l-3.5-3.2c-.4-.4-.4-1-.1-1.4s1-.4 1.4-.1l3.5 3.2c.4.4.4 1 .1 1.4-.3.5-1 .5-1.4.1z"
          className="color112544 svgShape"
        />
        <path
          fill="#b3adad"
          d="M533 339.5c-4.7 50.8-42.7 118.9-58.6 145.4-13.8 23-17.8 105.9-17.8 105.9-1.1 10-51.9 10.4-59.3 2-.7-.8-1-1.6-.9-2.5 0 0 4.7-96.8 14-126.2 9.6-30.2 30.4-73.3 44.1-100.3 7.6-15 13-25.1 13-25.1l65.5.8z"
          className="colorfc6054 svgShape"
        />
        <path
          fill="#757575"
          d="M533 339.5c-4.7 50.8-42.7 118.9-58.6 145.4-13.8 23-17.8 105.9-17.8 105.9-1.1 10-51.9 10.4-59.3 2 3.2-1.9 6.5-3.5 9.6-5.5 12.9-8.5 20.4-23.3 24.5-38.2 4.1-14.9 5.6-30.5 9.8-45.4 10.7-38.2 38.5-69.8 49.4-107.9 1.7-5.8 2.9-11.9 2.1-17.8-.8-6-4-11.9-9.3-14.7-8.8-4.6-19.3.3-28.8.5 7.6-15 13-25.1 13-25.1l65.4.8z"
          className="colore64b38 svgShape"
        />
        <path
          fill="#bbb6b6"
          d="M558.2 582.1c3.2 6 6.4 12.7 8.8 18.8 2.2 5.8 3.8 11.1 3.8 15 .1 4.5-3 8.4-7.4 9.3-18.1 3.7-64.1 11.8-65.8 6.9-2.9-8.4 8.1-16.6 15.9-21.6 3.7-2.3 6.3-5.8 7.6-9.8.7-2.2 1.1-4.6.9-7l36.2-11.6z"
          className="colorffc25e svgShape"
        />
        <path
          fill="#a7a9a9"
          d="M558.2 582.1c3.2 6 6.4 12.7 8.8 18.8-12.7 10.1-34.2 4.2-45.9-.3.7-2.2 1.1-4.6.9-7l36.2-11.5z"
          className="colorefa130 svgShape"
        />
        <path
          fill="#112c44"
          d="M503.1 628.1c-.6 0-1-.5-1-1 0-.6.4-1 1-1 .4 0 37 .6 63-10.5.5-.2 1.1 0 1.3.5.2.5 0 1.1-.5 1.3-18.2 7.8-43.8 10.7-63.8 10.7zm15.4-17.8l-4.2-2.4c-.5-.3-.6-.9-.4-1.4.3-.5.9-.6 1.4-.4l4.2 2.4c.5.3.6.9.4 1.4-.3.5-1 .6-1.4.4zm-3 3.3l-4.2-2.4c-.5-.3-.6-.9-.4-1.4s.9-.6 1.4-.4l4.2 2.4c.5.3.6.9.4 1.4-.3.5-.9.7-1.4.4zm5.9-6.7l-4.2-2.4c-.5-.3-.6-.9-.4-1.4.3-.5.9-.6 1.4-.4l4.2 2.4c.5.3.6.9.4 1.4-.3.5-.9.7-1.4.4z"
          className="color112544 svgShape"
        />
        <path
          fill="#b3adad"
          d="M504.8 339.5s-24.4 83.3-22.8 132.6c1 30.8 28.9 123.6 28.9 123.6 2.5 10.6 59.4-9 56.9-19.6 0 0-24.4-79.6-18.6-105.8 6.4-28.8 33-79 17.6-130.7h-62z"
          className="colorfc6054 svgShape"
        />
        <path
          fill="#112c44"
          d="M481 472.1c-1.6-48.8 22.6-132 22.8-132.9.2-.5.7-.8 1.2-.7.5.2.8.7.7 1.2-.2.8-24.3 83.7-22.7 132.3 0 .6-.4 1-1 1-.4.1-1-.3-1-.9z"
          className="color112544 svgShape"
        />
        <path
          fill="#fff"
          d="M588.4 390.3l-8.1 19.8c-1.4 3.4-5.2 5-8.6 3.6-2.3-1 12.4 5-48.1-19.5-3.4-1.4-5-5.2-3.6-8.6l8.1-19.8c1.4-3.4 5.2-5 8.6-3.6l48.1 19.5c1.2.5 2.2 1.3 2.9 2.3 1.3 1.7 1.6 4.1.7 6.3z"
          className="colorfff svgShape"
        />
        <path
          fill="#e1f1ff"
          d="M588.4 390.3l-8.1 19.8c-1.4 3.4-5.2 5-8.6 3.6l-46.4-18.9c2.1-1 5.4-.8 7.7-1.1 5.5-.8 10.3 3.1 15.1 5 5.2 2 17 4.4 22.2 2.6 5.2-1.9 9.7-5.7 12.4-10.5 1.4-2.4 2.4-5.4 5-6.8 1.3 1.7 1.6 4.1.7 6.3z"
          className="colore1e5ff svgShape"
        />
        <path
          fill="#112c44"
          d="M574.2 415.2c-2.7 0-1.1-.1-51-20.1-3.9-1.6-5.8-6.1-4.2-9.9l8.1-19.8c1.6-3.9 6.1-5.8 9.9-4.2l48.1 19.5c3.9 1.6 5.8 6 4.2 9.9l-8.1 19.8c-1.1 3-4 4.8-7 4.8zM529 366.1l-8.1 19.8c-1.2 2.9.2 6.2 3.1 7.3l48.1 19.5c2.9 1.2 6.2-.2 7.3-3.1l8.1-19.8c1.2-2.9-.2-6.2-3.1-7.3L536.3 363c-2.8-1.1-6.1.2-7.3 3.1zm59.4 24.2z"
          className="color112544 svgShape"
        />
        <path
          fill="#112c44"
          d="M541.7 380.5c0 2.7-2.2 4.8-4.8 4.8-2.7 0-4.8-2.2-4.8-4.8 0-2.7 2.2-4.8 4.8-4.8 2.6 0 4.8 2.1 4.8 4.8z"
          className="color112544 svgShape"
        />
        <path
          fill="#8dcaff"
          d="M538.5 380.5c0 .9-.7 1.6-1.6 1.6-.9 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.6.9 0 1.6.7 1.6 1.6zm31.6-38.8s-27.7 17.9-57.6 5c-29.9-12.9-44.8 9.2-50.7 3.8-7.9-7.1 8.6-26.4.8-51.3s-16.6-94.8 57.1-94.8c78.8 0 59 53.5 50.4 137.3z"
          className="color8d95ff svgShape"
        />
        <path
          fill="#448cca"
          d="M578.1 272.7c-1.5 19.6-5.3 42.8-8 69 0 0-27.7 17.9-57.6 5-29.9-12.9-44.8 9.2-50.7 3.8-3.5-3.2-2.2-8.8-.3-16.3 11.7-3.7 24.5-.3 36.4 1.8 12.4 2.2 25.4 3.2 37.6.1s23.8-10.7 29.4-22c4.1-8.5 4.5-18.1 3.9-27.5-.2-3.2-1.9-8.3-.6-11.4 1.5-3.6 5.7-3.7 9.9-2.5z"
          className="color757bff svgShape"
        />
        <path
          fill="#8dcaff"
          d="M517.3 204.4l-5.7 20.2S496.9 296 434.2 298h-.1c-48.4 1.5-76.3-59.3-76.3-59.3 7.7-6 14.7-15.7 15-25 0 0 4.5 1.8 11.6 5.6 11.5 6.2 29.8 17.4 46.2 34.2 0-.1 25.8-55 86.7-49.1z"
          className="color8d95ff svgShape"
        />
        <path
          fill="#448cca"
          d="M434.2 297.9c-48.4 1.5-76.3-59.3-76.3-59.3 7.7-6 14.7-15.7 15-25 0 0 4.5 1.8 11.6 5.6-1.2 2.9-3.1 5.6-5.1 7.1-3.7 2.9-8.3 4.9-10.9 8.8-3.3 4.8-2.7 11.5 0 16.7 2.7 5.2 7.2 9.2 11.7 13 16.1 13.8 33.8 27 54 33.1z"
          className="color757bff svgShape"
        />
        <path
          fill="#fcd9a4"
          d="M537.1 170.7c-.3 0 .4 34.6.4 34.6 1.4 9.3-13.1 10.6-24.4 4.1-2.8-1.6-5.5-3.3-5-6.5l1.2-7.7c-3.1.2-6.3.1-9.7-.3-21.2-2.5-5.8-45.1-.7-52.3 5.2-7.3 28.9-8 34.2-7.4 5.3.7 2.2 23.1 2.2 23.1s4.5-4.6 7.7.4c3.8 5.9-5.6 12-5.9 12z"
          className="colorfcbba4 svgShape"
        />
        <path
          fill="#112c44"
          d="M512.6 210.3c-2.6-1.5-6.1-3.5-5.5-7.5.1-.5.6-.9 1.1-.8.5.1.9.6.8 1.1-.4 2.5 1.6 3.8 4.5 5.5 10 5.8 24.2 5.4 22.9-3.1-.1-.5.3-1.1.8-1.1.5-.1 1.1.3 1.1.8 1.7 10.4-13.9 11.9-25.7 5.1z"
          className="color112544 svgShape"
        />
        <path
          fill="#e2ad5d"
          d="M589.1 372.4c3.4 2.3-7.9 36-13.6 33.9-3-1.1.6-10.6.7-11.9-2.1 6.4-5.5 17.4-9.6 15.7-3.2-1.3.4-11.5.6-13.3-2.5 7-4.5 14.6-8.6 13.2-3.2-1.1.9-10.7 1.3-13.2-2 4.9-4.8 12-8.3 10.2-4-2 9-34.9 9-34.9l-8.5-3.5s2.4-3.3 6.3-4.8c10.8-3.8 16-1.1 30.7 8.6z"
          className="colore2805d svgShape"
        />
        <path
          fill="#8dcaff"
          d="M589.6 374.2c-7.3-7.5-15.6-12-25.6-11.4 0 0 .2-8.9 2.8-21.2 3-14.1 9.2-32.8 22-47.9 0 0-62.4-40.8-40.5-68l1.4-17.5s67.1 14.2 77.5 68.6c.8 4 1.2 8.2 1.3 12.6 1.2 48.6-38.9 84.8-38.9 84.8z"
          className="color8d95ff svgShape"
        />
        <path
          fill="#112c44"
          d="M534.7 163.6l7.7 4.7s31.5-32.3-15.1-42.4c-19.8-4.3-49.7-10.8-35.5 16.6 0 0-12.4 2.6.1 18 .7-2.6 1.6-5.4 2.6-8.2 0 0 11.5 9.4 33.5 1 0 0-3.3 9.2 4.5 10.8l2.2-.5zm-39 2.9c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8-.8-1.8-1.8-1.8-1.8.8-1.8 1.8z"
          className="color112544 svgShape"
        />
        <path
          fill="#112c44"
          d="M493.8 160.1c-.2 1.1 2 1 4.8 1.6 2.8.5 5.1 1.4 5.3.3.2-1.1-1.9-2.4-4.7-2.9-2.7-.5-5.2-.1-5.4 1zm17.7 9.1c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8-.8-1.8-1.8-1.8-1.8.8-1.8 1.8zm-1.8-6.4c-.2 1.1 2 1 4.8 1.6 2.8.5 5.1 1.4 5.3.3.2-1.1-1.9-2.4-4.7-2.9-2.8-.6-5.2-.1-5.4 1z"
          className="color112544 svgShape"
        />
        <ellipse
          cx={536.8}
          cy={168.9}
          fill="#fcd9a4"
          rx={8.5}
          ry={6.2}
          transform="rotate(-77.661 536.853 168.894)"
          className="colorfcbba4 svgShape"
        />
        <path
          fill="#e2ad5d"
          d="M500.2 174.3c-1.1-2.5 3.4-6.8 5.3-8.5.4-.4 1-.3 1.4.1.4.4.3 1-.1 1.4-3.1 2.7-5.1 5.6-4.8 6.2 0 0 .4.4 2.5.2.6-.1 1 .3 1.1.9s-.3 1-.9 1.1c-1.6.1-3.8.1-4.5-1.4zm-3.6 7.7c-.3-.5-.1-1.1.3-1.4.5-.3 1.1-.1 1.4.3 1.5 2.4 4.8 1.5 7.3-.3.4-.3 1.1-.2 1.4.2.3.4.2 1.1-.2 1.4-4.7 3.5-8.6 2.4-10.2-.2zm39.5-11.1c-.5-.2-.8-.7-.6-1.3l.7-2.2c.2-.5.7-.8 1.3-.6.5.2.8.7.6 1.3l-.7 2.2c-.2.5-.8.8-1.3.6zm-26.8 24.3s4.8-.1 11.6-4.7c.8-.5 1.8.2 1.5 1.1-.8 3.4-3.7 9.2-14 9l.9-5.4z"
          className="colore2805d svgShape"
        />
        <path
          fill="#448cca"
          d="M589.6 374.2c-7.3-7.5-15.6-12-25.6-11.4 0 0 .2-8.9 2.8-21.2 3.9 1 7.1 5.8 8.5 9.3 1.2 3.2 2.3 6.6 4.7 9.1 4.7 4.8 12.9 4.1 18.4.3 5.4-3.9 8.6-10.1 11.4-16.2 9.4-21.3 15.6-44.1 17.6-67.3.8 4 1.2 8.2 1.3 12.6 1 48.6-39.1 84.8-39.1 84.8z"
          className="color757bff svgShape"
        />
        <path
          fill="#112c44"
          d="M588.9 374.9c-7.9-8-16-11.7-24.8-11.1-.6 0-1.1-.5-1.1-1 0-.4 1.1-40.5 24.3-68.8-5.8-3.9-35.8-25.1-43.1-46.5-.2-.5.1-1.1.6-1.3.5-.2 1.1.1 1.3.6 7.7 22.6 42.9 45.8 43.2 46.1.5.3.6 1 .2 1.5-21.3 25.3-24.2 60.8-24.5 67.4 9-.3 17.3 3.6 25.3 11.7.4.4.4 1 0 1.4-.4.4-1.1.4-1.4 0z"
          className="color112544 svgShape"
        />
        <path
          fill="#112c44"
          d="M461.2 351.2c-8.7-7.8 8.3-26.8.5-51.8-5.8-18.6-8.7-47.8 2.6-68.6.3-.5.9-.7 1.4-.4.5.3.7.9.4 1.4-10.9 20.3-8.1 48.8-2.4 67 7.8 24.8-8.2 44.5-1.1 50.9 4.8 4.4 20.6-16.9 50.4-4 23 9.9 44.7 1.3 53-2.8.5-.3 1.1-.1 1.3.4s.1 1.1-.4 1.3c-8.5 4.3-30.9 13.2-54.7 2.9-30-13-44.1 9.9-51 3.7z"
          className="color112544 svgShape"
        />
        <path
          fill="#fff"
          d="M366.1 418.7H203.2c-15.6 0-30.6-12.6-33.6-28.2-1.4-7.5-44.7-234.6-46.7-244.9-3-15.6 7.3-28.2 22.8-28.2h162.9c15.6 0 30.6 12.6 33.6 28.2 1.9 10 44.6 234.2 46.7 244.9 3 15.6-7.2 28.2-22.8 28.2z"
          className="colorfff svgShape"
        />
        <path
          fill="#e1f1ff"
          d="M366.1 418.7H203.2c-15.6 0-30.6-12.6-33.6-28.2l-2.1-10.7c20.5 3.9 152 69.7 190.7-18.9 8.3-19.1 7.9-41.1 2.3-101-1.3-13.8-2.8-27.5-4.5-41.2-1.2-9.4-4.7-20.9-5.7-31.4L389 390.5c2.9 15.6-7.3 28.2-22.9 28.2zm-207-276.4c1 5.5-2.5 9.9-8 9.9s-10.7-4.4-11.8-9.9c-1-5.5 2.5-9.9 8-9.9 5.5.1 10.7 4.5 11.8 9.9z"
          className="colore1e5ff svgShape"
        />
        <path
          fill="#cce7ff"
          d="M159.1 142.3c1 5.5-2.5 9.9-8 9.9-3.1 0-6.1-1.4-8.3-3.6 1.5.7 3 1.1 4.7 1.1 8.4 0 11-9.8 4.6-16.2 3.4 1.7 6.2 5 7 8.8z"
          className="colorccd2ff svgShape"
        />
        <path
          fill="#112c44"
          d="M366.1 419.7H203.2c-16 0-31.5-13-34.6-29l-46.7-244.9c-3.1-16.3 7.7-29.4 23.8-29.4h162.9c16 0 31.5 13 34.6 29l46.7 244.9c3.1 16.3-7.7 29.4-23.8 29.4zM145.8 118.3c-15.1 0-24.7 12.2-21.9 27l46.7 244.9c2.9 15.1 17.5 27.4 32.6 27.4h162.9c15.1 0 24.7-12.2 21.9-27l-46.7-244.9c-2.9-15.1-17.5-27.4-32.6-27.4H145.8z"
          className="color112544 svgShape"
        />
        <path
          fill="#e1f1ff"
          d="M183.9 211.9c6.4 33.8 39.1 61.3 73 61.3 33.8 0 56-27.4 49.6-61.3-6.4-33.8-39.1-61.3-72.9-61.3-33.9 0-56.1 27.4-49.7 61.3z"
          className="colore1e5ff svgShape"
        />
        <path
          fill="#cce7ff"
          d="M306.5 211.9c6.4 33.8-15.7 61.3-49.6 61.3-16.6 0-33-6.6-46.1-17.4 10.8 6.2 22.9 9.8 35.1 9.8 33.8 0 56-27.4 49.6-61.3-3.3-17.2-13.3-32.8-26.9-43.9 19 10.9 33.8 29.9 37.9 51.5z"
          className="colorccd2ff svgShape"
        />
        <path
          fill="#b3adad"
          d="M272.8 270.9c10.2-3 18.5-8.8 24.4-16.3-14.9-9.6-28.4-11.2-28.4-11.2l4 27.5z"
          className="colorfc6054 svgShape"
        />
        <path
          fill="#b3adad"
          d="M210.5 255.5c19.8 16.5 43.7 20.9 62.4 15.5 8.1-2.3 15-6.5 20.6-12-5-6.6-11.1-11.7-18.6-13.9-2-.6-4-1.1-6-1.6-25.9-6-47.4-1.1-58.4 12z"
          className="colorfc6054 svgShape"
        />
        <path
          fill="#fcd9a4"
          d="M253.9 218.1s-3.3 12 6.1 23.7c3.3 11.4-26.6 11.8-29.1 1.6 0 0-2.6-15.1-8.1-25.6l31.1.3z"
          className="colorfcbba4 svgShape"
        />
        <path
          fill="#112c44"
          d="M229.9 243.7c-.1-.5.2-1.1.7-1.2.5-.1 1.1.2 1.2.7 2.2 9 30 8.8 27.2-1.1-.2-.5.2-1.1.7-1.2.5-.1 1.1.2 1.2.7 3.8 12.9-28.2 13.5-31 2.1z"
          className="color112544 svgShape"
        />
        <path
          fill="#fcd9a4"
          d="M219.1 206.1c2.9 15 10.7 24.4 25.4 27.1 29.2 5.3 23.5-10.7 19.4-25.7-5.6-20.6-18.6-28.6-29.8-28.6-11.1.1-17.9 12.2-15 27.2z"
          className="colorfcbba4 svgShape"
        />
        <path
          fill="#112c44"
          d="M266 189.1c-.8 9.7-3.7 13.4-38.4 6.4 0 0 10.3 5.8 1.7 9.8l1.5 6.6c.2.9-1 1.2-1.5.4-1.5-2.1-3.5-4.4-5.4-4.8-3.6-.7-.1 12-.1 12s-2 2.5-6.1-2.8c-4.5-5.9-22.3-40.4-.4-35.5 0 0-5-15.7 22.1-13.7 23.6 1.7 27.2 14.1 26.6 21.6z"
          className="color112544 svgShape"
        />
        <path
          fill="#fcd9a4"
          d="M218.1 211.9c.6 3.4 3.6 6.2 6.6 6.2 3 0 4.9-2.8 4.3-6.2-.6-3.4-3.6-6.2-6.6-6.2s-4.9 2.8-4.3 6.2z"
          className="colorfcbba4 svgShape"
        />
        <path
          fill="#e2ad5d"
          d="M255.5 234.3s-11.7 0-18.1-2.9c-.6-.3-1.1.3-.8.8 1.7 3.1 7 9.5 21.7 7.4-1-1.6-2-3.5-2.8-5.3z"
          className="colore2805d svgShape"
        />
        <path
          fill="#112c44"
          d="M255.2 206.6c-1.9-1 1.7-5 5.6-2.5.5.3.6.9.3 1.4-.3.5-.9.6-1.4.3-1.1-.7-2.6-.7-3.2.4-.2.5-.8.6-1.3.4zm3.3 2.8l-.3-.8c-.2-.5 0-1.1.5-1.3.5-.2 1.1 0 1.3.5l.3.8c.2.5 0 1.1-.5 1.3-.5.2-1.1 0-1.3-.5zm-18.3-1.6c-1.9-1 1.7-5 5.6-2.5.5.3.6.9.3 1.4-.3.5-.9.6-1.4.3-1.1-.7-2.6-.7-3.2.4-.2.5-.8.7-1.3.4zm3.3 2.8l-.3-.8c-.2-.5 0-1.1.5-1.3.5-.2 1.1 0 1.3.5l.3.8c.2.5 0 1.1-.5 1.3-.5.2-1.1 0-1.3-.5z"
          className="color112544 svgShape"
        />
        <path
          fill="#e2ad5d"
          d="M254.5 217.3c-.5 0-1-.4-1-1s.4-1 1-1c.4 0 .9-.1 1.4-.1-.1-1.1-2.8-4.5-6-7.6-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0 2.8 2.7 7.3 7.5 6.5 9.7-.1.4-.5 1.1-1.6 1.2-1 .2-1.5.2-1.7.2zm.5 6.7c-.5-.3-.6-.9-.3-1.4.3-.5.9-.6 1.4-.3 3.9 2.4 4.4.3 4.3.4.1-.5.7-.9 1.2-.7.5.1.9.7.7 1.2-.5 2.1-3 3.5-7.3.8zm-32.3-11.8l-.9-1.2c-.3-.4-.3-1.1.2-1.4.4-.3 1.1-.3 1.4.2l.9 1.2c.3.4.3 1.1-.2 1.4-.4.3-1 .2-1.4-.2z"
          className="colore2805d svgShape"
        />
        <path
          fill="#112c44"
          d="M256.9 274.2c-34.2 0-67.4-27.9-73.9-62.1-6.6-34.7 16.3-62.5 50.6-62.5 34.2 0 67.4 27.8 73.9 62.1 6.6 34.6-16.2 62.5-50.6 62.5zm-72-62.5c6.4 33.3 38.6 60.5 72 60.5 33.7 0 54.9-27.3 48.6-60.1-6.4-33.3-38.6-60.5-72-60.5-33.1 0-54.9 26.7-48.6 60.1z"
          className="color112544 svgShape"
        />
        <path
          fill="#e1f1ff"
          d="M337.5 304.9l4.4 22.9c.5 2.8-1.3 5.1-4.2 5.1H198.9c-2.8 0-5.6-2.3-6.1-5.1l-4.4-22.9c-.5-2.8 1.3-5.1 4.2-5.1h138.8c2.8 0 5.6 2.3 6.1 5.1z"
          className="colore1e5ff svgShape"
        />
        <path
          fill="#cce7ff"
          d="M337.5 304.9l4.4 22.9c.5 2.8-1.3 5.1-4.2 5.1H198.9c-2.8 0-5.6-2.3-6.1-5.1l-.1-.6c1 .2-8.3.1 139.9.1 2.8 0 4.7-2.3 4.2-5.1l-4.2-22.3c2.3.5 4.4 2.6 4.9 5z"
          className="colorccd2ff svgShape"
        />
        <path
          fill="#e1f1ff"
          d="M347.5 357.4l4.4 22.9c.5 2.8-1.3 5.1-4.2 5.1H208.9c-2.8 0-5.6-2.3-6.1-5.1l-4.4-22.9c-.5-2.8 1.3-5.1 4.2-5.1h138.8c2.8-.1 5.5 2.2 6.1 5.1z"
          className="colore1e5ff svgShape"
        />
        <path
          fill="#cce7ff"
          d="M347.5 357.4l4.4 22.9c.5 2.8-1.3 5.1-4.2 5.1H208.9c-2.8 0-5.6-2.3-6.1-5.1l-.1-.6c1 .2-8.3.1 139.9.1 2.8 0 4.7-2.3 4.2-5.1l-4.2-22.3c2.3.5 4.4 2.5 4.9 5z"
          className="colorccd2ff svgShape"
        />
        <path
          fill="#112c44"
          d="M337.7 334H198.9c-3.3 0-6.5-2.6-7.1-6l-4.4-22.9c-.7-3.5 1.7-6.3 5.1-6.3h138.8c3.3 0 6.5 2.6 7.1 6l4.4 22.9c.7 3.4-1.6 6.3-5.1 6.3zm-145.1-33.2c-2.2 0-3.6 1.7-3.2 4l4.4 22.9c.5 2.4 2.8 4.3 5.1 4.3h138.8c2.2 0 3.6-1.7 3.2-4l-4.4-22.9c-.5-2.4-2.8-4.3-5.1-4.3H192.6zm155.1 85.6H208.9c-3.3 0-6.5-2.6-7.1-6l-4.4-22.9c-.7-3.5 1.7-6.3 5.1-6.3h138.8c3.3 0 6.5 2.6 7.1 6l4.4 22.9c.7 3.5-1.6 6.3-5.1 6.3zm-145.1-33.2c-2.2 0-3.6 1.7-3.2 4l4.4 22.9c.5 2.4 2.8 4.3 5.1 4.3h138.8c2.2 0 3.6-1.7 3.2-4l-4.4-22.9c-.5-2.4-2.8-4.3-5.1-4.3H202.6z"
          className="color112544 svgShape"
        />
        <path
          fill="#8dcaff"
          d="M213.9 316.4c.7 3.5-1.6 6.4-5.2 6.4-3.5 0-6.9-2.8-7.6-6.4-.7-3.5 1.6-6.4 5.1-6.4 3.6 0 7 2.9 7.7 6.4z"
          className="color8d95ff svgShape"
        />
        <path
          fill="#448cca"
          d="M213.9 316.4c.7 3.5-1.6 6.4-5.2 6.4-2.8 0-5.6-1.8-6.9-4.4 5.6 3.5 11.4-1.4 8.3-7.2 2 1.1 3.4 3 3.8 5.2z"
          className="color757bff svgShape"
        />
        <path
          fill="#112c44"
          d="M200.2 316.6c-.8-4.1 2-7.5 6.1-7.5 6.5 0 11.2 7.9 7.3 12.5-3.8 4.6-12.2 1.4-13.4-5zm2.8-4.1c-.8 1-1.1 2.3-.8 3.7.9 4.9 7.3 7.3 9.9 4.1.8-1 1.1-2.3.8-3.7-.9-4.9-7.3-7.3-9.9-4.1z"
          className="color112544 svgShape"
        />
        <path
          fill="#8dcaff"
          d="M236.6 316.4c.7 3.5-1.6 6.4-5.2 6.4-3.5 0-6.9-2.8-7.6-6.4-.7-3.5 1.6-6.4 5.1-6.4 3.6 0 7 2.9 7.7 6.4z"
          className="color8d95ff svgShape"
        />
        <path
          fill="#448cca"
          d="M236.6 316.4c.7 3.5-1.6 6.4-5.2 6.4-2.8 0-5.6-1.8-6.9-4.4 5.6 3.5 11.4-1.4 8.3-7.2 2 1.1 3.4 3 3.8 5.2z"
          className="color757bff svgShape"
        />
        <path
          fill="#112c44"
          d="M231.5 323.7c-6.5 0-11.2-7.9-7.3-12.5 3.8-4.6 12.2-1.4 13.4 5 .8 4.2-2 7.5-6.1 7.5zM229 311c-2.9 0-4.7 2.3-4.2 5.2.9 4.9 7.3 7.3 9.9 4.1.8-1 1.1-2.3.8-3.7-.5-3.1-3.4-5.6-6.5-5.6z"
          className="color112544 svgShape"
        />
        <path
          fill="#8dcaff"
          d="M259.3 316.4c.7 3.5-1.6 6.4-5.2 6.4-3.5 0-6.9-2.8-7.6-6.4-.7-3.5 1.6-6.4 5.1-6.4 3.6 0 7 2.9 7.7 6.4z"
          className="color8d95ff svgShape"
        />
        <path
          fill="#448cca"
          d="M259.3 316.4c.7 3.5-1.6 6.4-5.2 6.4-2.8 0-5.6-1.8-6.9-4.4 5.6 3.5 11.4-1.4 8.3-7.2 2 1.1 3.4 3 3.8 5.2z"
          className="color757bff svgShape"
        />
        <path
          fill="#112c44"
          d="M254.2 323.7c-6.4 0-11.2-7.9-7.3-12.5 3.8-4.6 12.2-1.4 13.4 5 .8 4.1-2 7.5-6.1 7.5zm-2.5-12.7c-2.8 0-4.7 2.2-4.2 5.2.9 4.9 7.3 7.3 9.9 4.1.8-1 1.1-2.3.8-3.7-.5-3.1-3.4-5.6-6.5-5.6z"
          className="color112544 svgShape"
        />
        <path
          fill="#8dcaff"
          d="M282 316.4c.7 3.5-1.6 6.4-5.2 6.4-3.5 0-6.9-2.8-7.6-6.4-.7-3.5 1.6-6.4 5.1-6.4 3.6 0 7 2.9 7.7 6.4z"
          className="color8d95ff svgShape"
        />
        <path
          fill="#448cca"
          d="M282 316.4c.7 3.5-1.6 6.4-5.2 6.4-2.8 0-5.6-1.8-6.9-4.4 4.7 3 10 0 9-5.2-.1-.7-.4-1.4-.7-2 2 1.1 3.4 3 3.8 5.2z"
          className="color757bff svgShape"
        />
        <path
          fill="#112c44"
          d="M268.3 316.6c-.4-2 .1-3.9 1.3-5.4 1.2-1.4 2.9-2.2 4.9-2.2 6.5 0 11.2 7.9 7.3 12.5-1.2 1.5-12.3 1.5-13.5-4.9zm2.8-4.1c-2.7 3.3.8 9.3 5.8 9.3 1.4 0 2.6-.5 3.3-1.5.8-1 1.1-2.3.8-3.7-.9-4.9-7.2-7.3-9.9-4.1z"
          className="color112544 svgShape"
        />
        <path
          fill="#8dcaff"
          d="M304.7 316.4c.7 3.5-1.6 6.4-5.2 6.4-3.5 0-6.9-2.8-7.6-6.4-.7-3.5 1.6-6.4 5.1-6.4 3.7 0 7.1 2.9 7.7 6.4z"
          className="color8d95ff svgShape"
        />
        <path
          fill="#448cca"
          d="M304.7 316.4c.7 3.5-1.6 6.4-5.2 6.4-2.8 0-5.6-1.8-6.9-4.4 4.7 3 10 0 9-5.2-.1-.7-.4-1.4-.7-2 2 1.1 3.4 3 3.8 5.2z"
          className="color757bff svgShape"
        />
        <path
          fill="#112c44"
          d="M299.6 323.7c-4 0-7.8-3.2-8.6-7.2-.4-2 .1-3.9 1.3-5.4 3.8-4.6 12.2-1.4 13.4 5 .8 4.3-2 7.6-6.1 7.6zm-5.8-11.2c-.8 1-1.1 2.3-.8 3.7.9 4.9 7.3 7.3 9.9 4.1.8-1 1.1-2.3.8-3.7-.9-4.9-7.2-7.3-9.9-4.1z"
          className="color112544 svgShape"
        />
        <path
          fill="#8dcaff"
          d="M327.4 316.4c.7 3.5-1.6 6.4-5.2 6.4-3.5 0-6.9-2.8-7.6-6.4-.7-3.5 1.6-6.4 5.1-6.4 3.7 0 7.1 2.9 7.7 6.4z"
          className="color8d95ff svgShape"
        />
        <path
          fill="#448cca"
          d="M327.4 316.4c.7 3.5-1.6 6.4-5.2 6.4-2.8 0-5.6-1.8-6.9-4.4 5.6 3.5 11.4-1.4 8.3-7.2 2 1.1 3.4 3 3.8 5.2z"
          className="color757bff svgShape"
        />
        <path
          fill="#112c44"
          d="M322.3 323.7c-6.4 0-11.2-7.9-7.3-12.5 3.8-4.6 12.2-1.4 13.4 5 .8 4.1-2 7.5-6.1 7.5zm-5.8-11.2c-2.7 3.3.8 9.3 5.8 9.3 2.8 0 4.7-2.2 4.2-5.2-1-4.9-7.3-7.3-10-4.1zM336.1 369.8H217.7c-.6 0-1-.4-1-1s.4-1 1-1h118.4c.6 0 1 .4 1 1s-.4 1-1 1zM138.3 142.5c-1.1-6 2.8-11.1 9-11.1 5.9 0 11.6 4.8 12.8 10.7.6 3-.1 5.8-1.8 7.9-5.8 6.9-18.2 2-20-7.5zm9-9c-4.8 0-8 3.9-7 8.7 1.5 8 12 12 16.4 6.6 1.4-1.6 1.9-3.9 1.4-6.2-1-5.1-5.8-9.1-10.8-9.1z"
          className="color112544 svgShape"
        />
        <path
          fill="#112c44"
          d="M152.4 146.1c-.7 0-.4-.1-7.1-5.7-.4-.4-.5-1-.1-1.4.4-.4 1-.5 1.4-.1l6.5 5.4c.7.6.2 1.8-.7 1.8z"
          className="color112544 svgShape"
        />
        <path
          fill="#112c44"
          d="M146.3 145.8c-.4-.3-.5-1-.1-1.4l4.4-5.4c.3-.4 1-.5 1.4-.1.4.3.5 1 .1 1.4l-4.4 5.4c-.3.4-.9.5-1.4.1z"
          className="color112544 svgShape"
        />
        <path
          fill="#fcd9a4"
          d="M371.8 211.4s-9.2-24.4-20.2-25.3c-9-.8-18.8 4.7-17.5 9.8 1.3 5 13.4 2.4 13.4 2.4s-.4 13 9.6 14.1"
          className="colorfcbba4 svgShape"
        />
        <g>
          <path
            fill="#112c44"
            d="M357 213.4c-8.9-1-10.2-10.5-10.4-13.9-3.4.6-12.1 1.6-13.4-3.3-.4-1.6 0-3.2 1.2-4.8 2.6-3.5 8.7-6.1 14.7-6.4.6-.1 1 .4 1 1s-.4 1-1 1c-7.5.3-15 4.8-14.1 8.6.8 3.3 9.3 2.3 12.2 1.6.6-.1 1.2.4 1.2 1 0 .5-.3 12.1 8.7 13.1.5.1.9.6.9 1.1.1.7-.4 1.1-1 1z"
            className="color112544 svgShape"
          />
        </g>
        <g>
          <path
            fill="#112c44"
            d="M433.1 207.9c-.5-.1-.9-.6-.8-1.2 9.1-46.3-23.2-87-64.3-87-34.8 0-58.9 30.7-54.2 52.3.1.5-.2 1.1-.8 1.2-.5.1-1.1-.2-1.2-.8-5.1-23.4 20.9-55.1 56.7-54.8 42.1.4 75 41.9 65.7 89.4 0 .7-.5 1-1.1.9z"
            className="color112544 svgShape"
          />
        </g>
        <g>
          <path
            fill="#112c44"
            d="M312.9 173.4c-.9-.1 0 .2-8.3-8.1-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l6.9 7.1 7.1-6.9c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7.8 7.6c-.2.2-.4.3-.7.3z"
            className="color112544 svgShape"
          />
        </g>
      </svg>
    </svg>
  );
};
