export const SelfieForDocIllustration = (props: any) => {
  return (
    <svg
      width={130}
      height={250}
      viewBox="0 0 130 250"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M52.968 241.324c-.144 1.152-1.152 2.016-2.304 1.92L27 241.996c-3.216-.144-5.712-2.928-5.568-6.192l.144-3.072.096-1.344.576-10.896 18.528 3.024v1.104c.048 1.2.24 2.448.72 3.504.384.864.96 1.488 1.776 1.872 3.792 1.584 4.704.864 6.768 2.064 3.312 2.016 3.264 6.576 2.928 9.264z"
        fill="#989797"
      />
      <path
        d="M41.496 228.172l-19.92 4.608.096-1.344.576-10.896 18.528 3.024v1.104c.048 1.248.24 2.496.72 3.504z"
        fill="#3A3939"
      />
      <path
        d="M49.224 239.692l-23.472-1.92c-.288 0-.48-.24-.432-.528 0-.288.24-.48.528-.432l23.472 1.92c.288 0 .48.24.432.528-.048.288-.288.48-.528.432z"
        fill="#112C44"
      />
      <path
        d="M115.128 223.084c-2.256-.72-3.024.192-7.056-.528-1.968-.336-3.024-2.496-3.648-4.656-.096-.384-.192-.72-.288-1.104l-18.72 1.152 1.872 10.752.768 4.368c.576 3.168 3.6 5.328 6.768 4.8l23.328-4.032c1.152-.192 1.92-1.248 1.824-2.4-.24-2.688-1.248-7.152-4.848-8.352z"
        fill="#989797"
      />
      <path
        d="M85.416 217.996l1.872 10.752 17.136-10.848c-.096-.384-.192-.72-.288-1.104l-18.72 1.2z"
        fill="#3A3939"
      />
      <path
        d="M105.624 217.42c-8.256 3.216-25.632 5.28-26.688.624 0 0-3.408-44.688-26.496-61.44-17.568-12.768-16.416-28.848-14.688-46.32l33.984-2.448c.048.048 3.408 3.744 8.112 12.048 8.256 14.4 21.024 43.008 29.712 92.16.144.672.24 1.392.384 2.112.144 1.008-1.584 2.208-4.32 3.264z"
        fill="#8DCAFF"
      />
      <path
        d="M105.624 217.42c-8.256 3.216-25.632 5.28-26.688.624 0 0-3.408-44.688-26.496-61.44-17.568-12.768-16.416-28.848-14.688-46.32l33.984-2.448s2.64 2.88 6.672 9.6c-3.12 1.584-6.816 2.016-9.792 3.84-3.84 2.352-5.664 7.056-5.712 11.568-.048 4.464 1.344 8.832 2.784 13.104 4.176 12.576 8.352 25.104 12.528 37.632 2.4 7.2 4.848 14.544 9.312 20.64 4.512 6.096 10.944 10.752 18.096 13.2z"
        fill="#75BFFF"
      />
      <path
        d="M92.136 233.644c-.048-.24.144-.528.384-.528l23.328-3.36c.24-.048.528.144.528.384.048.24-.144.528-.384.528l-23.328 3.36c-.24.048-.48-.144-.528-.384z"
        fill="#112C44"
      />
      <path
        d="M16.968 217.756s14.832-42.288.432-66.912c-10.944-18.768-3.456-33.024 5.136-48.336l32.112 8.304c.384 5.472 11.088 38.256-7.248 113.712a32.543 32.543 0 01-.528 2.064c-1.056 4.32-31.104-2.592-29.904-8.832z"
        fill="#8DCAFF"
      />
      <path
        d="M57.576 161.596c-.288 0-.48-.24-.432-.528 1.104-17.232.48-33.12-2.976-50.208-.048-.24.096-.528.384-.576.24-.048.528.096.576.384 3.504 17.184 4.08 33.36 2.976 50.448 0 .288-.24.48-.528.48z"
        fill="#112C44"
      />
      <path
        d="M108.072 44.524s-4.656-6.72-7.632-6.96c-2.976-.24.912 7.344.912 7.344l6.72-.384z"
        fill="#FCD9A4"
      />
      <path
        d="M107.832 69.628c-1.536 3.264-3.504 6.48-5.76 9.264-1.824 2.256-3.84 4.224-5.952 5.664-5.76 3.888-12.336 4.032-18.24-4.032.144 5.28-.24 9.696-.384 13.536-.144 4.416.48 7.92.864 11.808.096.816.144 1.632.192 2.448.72 15.168-11.424 0-58.56 2.928 0 0-13.728 3.168 2.88-17.76-4.32-7.776-3.36-21.168-2.352-30.048 1.584-14.304 10.464-19.392 19.008-20.256 3.648-.384 13.104-.384 13.104-.384 3.552 0 7.296-.144 10.32.144 0 0 16.08-.096 21.888 25.2 0 0 2.88-7.824 9.935-15.36 2.4-2.592 5.281-5.136 8.689-7.344 1.248 4.032 4.848 2.832 7.056 6.336 2.064 3.36.72 10.704-2.688 17.856z"
        fill="#66A3D8"
      />
      <path
        d="M19.992 111.292s-13.728 3.168 2.88-17.76c-1.776-3.168-2.64-7.248-2.976-11.52 1.104 0 2.208.192 3.216.576 1.92.672 2.352 1.152 1.968 3.072-.432 2.256-.912 4.416-.912 6.72 0 3.024.816 6.144 2.784 8.4 2.256 2.544 5.664 3.648 8.928 4.464 13.872 3.456 28.512 3.696 42.48.624.096.816.144 1.632.192 2.448.72 15.216-11.424.048-58.56 2.976z"
        fill="#448CCA"
      />
      <path
        d="M20.04 111.772c-.48.096-4.608.912-5.664-2.016-.096-.24.048-.528.288-.624.24-.096.528.048.624.288.768 2.256 4.56 1.392 4.608 1.392h.096c38.208-2.352 53.808 7.152 57.264 2.736.144-.192.48-.24.672-.096.192.144.24.48.096.672-4.176 5.28-18.72-4.8-57.984-2.352z"
        fill="#112C44"
      />
      <path
        d="M107.832 69.628c-1.536 3.264-3.504 6.48-5.76 9.264-1.824 2.256-3.84 4.224-5.952 5.664 2.784-4.08 4.752-8.688 5.712-13.536.576-2.88.768-5.952-.288-8.64-.672-1.728-1.872-3.216-3.024-4.704-1.2-1.488-2.352-2.976-3.552-4.512-.096-.144-.192-.24-.288-.336 2.4-2.592 5.28-5.136 8.688-7.344 1.248 4.032 4.848 2.832 7.056 6.336 2.16 3.312.816 10.656-2.592 17.808z"
        fill="#448CCA"
      />
      <path
        d="M77.88 80.956a.481.481 0 01-.48-.48c-.144-6.432-1.584-11.856-4.32-16.512-.144-.24-.048-.528.192-.672.24-.144.528-.048.672.192 2.832 4.848 4.272 10.416 4.464 16.992-.048.24-.24.432-.528.48zm4.608-5.856h-.144a.521.521 0 01-.336-.576c1.296-4.608 2.352-6.288 2.4-6.384.144-.24.432-.288.672-.144.24.144.288.432.144.672 0 0-1.008 1.68-2.304 6.144-.048.144-.24.288-.432.288z"
        fill="#112C44"
      />
      <path
        d="M59.016 97.612c1.104.528 3.792.144 4.896.192 2.88.096 3.744-1.008 3.072-1.824-.192-.24-.48-.384-.816-.48 1.968-.144 3.024-1.2 2.496-1.824-.24-.288-.624-.432-1.152-.528 1.872-.192 2.688-1.248 2.16-1.872-.24-.336-.864-.48-1.632-.528 1.536-.24 5.856-.432 5.664-1.728-.384-2.064-9.504-.528-9.504-.528s3.6-1.44 2.976-2.832c-.576-1.296-5.472-.432-6.72.72-2.208 1.968-8.016 8.208-1.44 11.232z"
        fill="#FCD9A4"
      />
      <path
        d="M12.456 87.244C21.144 98.668 58.008 97.42 58.008 97.42c-.528-4.992-.192-8.496 2.4-11.472 0 0-.24-.192-.72-.528-3.072-2.112-15.36-9.84-26.688-7.68 0 0 14.496-16.896 6.144-25.92-2.592-.528 6.624 1.392-12.624-2.64 0 .048-22.752 26.64-14.064 38.064z"
        fill="#66A3D8"
      />
      <path
        d="M12.456 87.244C21.144 98.668 58.008 97.42 58.008 97.42c-.528-4.992-.192-8.496 2.4-11.472 0 0-.24-.192-.72-.528-3.84.288-6.096 4.032-9.072 6.192-5.328 3.84-12.72 2.688-18.912.432-4.416-1.632-8.88-3.888-11.568-7.728-3.216-4.608-3.408-10.752-1.824-16.128 1.584-5.376 8.208-18.24 8.208-18.96 0 0-22.752 26.592-14.064 38.016z"
        fill="#448CCA"
      />
      <path
        d="M12.072 87.532c-8.784-11.568 13.152-37.536 14.064-38.64.192-.192.48-.24.672-.048.192.192.24.48.048.672-.24.24-22.368 26.448-14.064 37.44 7.872 10.368 40.128 10.08 44.592 9.984-.432-4.368-.144-7.92 2.256-10.896-2.448-1.68-15.168-10.032-26.64-7.824-.432.096-.768-.432-.432-.768.144-.144 14.112-16.704 6.192-25.248-.192-.192-.192-.48.048-.672.192-.192.48-.192.672.048 7.44 8.016-2.352 21.744-5.328 25.536 12.768-1.68 25.968 8.064 26.544 8.496.24.144.24.48.096.72-2.592 2.928-2.784 6.432-2.304 11.088.048.288-.192.528-.48.528-11.808.336-38.688-.816-45.936-10.416z"
        fill="#112C44"
      />
      <path d="M32.952 77.788s-3.168.048-6.096.72z" fill="#fff" />
      <path
        d="M26.376 78.604c-.048-.24.096-.528.384-.576 2.976-.672 6.048-.72 6.192-.72.672 0 .624.96 0 .96-.048 0-3.12.096-6 .72-.24.048-.528-.144-.576-.384z"
        fill="#112C44"
      />
      <path
        d="M102.216 33.244l-.912 5.664-4.56 28.032c-.24 1.344-1.392 2.352-2.736 2.352H75.192c-1.728 0-3.024-1.536-2.736-3.216l.576-3.552 4.896-30.096c.24-1.344 1.392-2.352 2.736-2.352H99.48c1.728-.096 3.024 1.44 2.736 3.168z"
        fill="#fff"
      />
      <path
        d="M101.304 38.86l-4.56 28.032c-.24 1.344-1.392 2.352-2.736 2.352H75.192c-1.728 0-3.024-1.536-2.736-3.216l.576-3.552c.48-.144.96-.24 1.44-.288 3.168-.432 6.432 0 9.6-.624 5.376-1.056 9.744-5.136 12.432-9.84 1.344-2.304 2.352-4.8 3.12-7.344.48-1.536.864-3.696 1.68-5.52z"
        fill="#E1F1FF"
      />
      <path
        d="M93.96 69.724H75.144c-2.016 0-3.552-1.824-3.216-3.792l5.52-33.696c.24-1.584 1.632-2.736 3.216-2.736H99.48c2.016 0 3.552 1.824 3.216 3.792l-5.52 33.696c-.24 1.584-1.584 2.736-3.216 2.736zM80.664 30.46c-1.152 0-2.112.816-2.256 1.92l-5.52 33.696c-.24 1.392.864 2.688 2.256 2.688H93.96c1.152 0 2.112-.816 2.256-1.92l5.52-33.696c.24-1.392-.864-2.688-2.256-2.688H80.664z"
        fill="#112C44"
      />
      <path
        d="M80.664 29.98c-1.344 0-2.544 1.008-2.736 2.352l-5.52 33.696c-.288 1.68 1.056 3.216 2.736 3.216h-2.928c-1.728 0-3.024-1.536-2.736-3.216L75 32.332c.24-1.344 1.392-2.352 2.736-2.352h2.928z"
        fill="#989797"
      />
      <path
        d="M75.144 69.724h-2.928c-2.016 0-3.552-1.824-3.216-3.792l5.52-33.696c.24-1.584 1.632-2.736 3.216-2.736h2.928c.288 0 .48.192.48.48s-.192.48-.48.48c-1.152 0-2.112.816-2.256 1.92l-5.52 33.696c-.24 1.392.864 2.688 2.256 2.688.288 0 .48.192.48.48s-.192.48-.48.48zm2.592-39.264c-1.152 0-2.064.816-2.256 1.92l-5.52 33.696c-.24 1.392.864 2.688 2.256 2.688h.576c-.768-.768-1.056-1.824-.912-2.832l5.52-33.696a3.175 3.175 0 01.912-1.776h-.576z"
        fill="#112C44"
      />
      <path
        d="M105.768 43.372s-8.736-1.68-9.648-.288c-.912 1.392 2.496 2.304 2.496 2.304s-3.648-.24-3.36 1.152c.288 1.392 3.12 1.488 3.12 1.488s-3.264-.528-3.408.912c-.096 1.44 3.36 1.296 3.36 1.296s-3.84-.336-3.072 1.296c.768 1.632 3.456 1.584 3.456 1.584s-.336 2.88 1.488 4.176c4.128 2.88 8.16-.144 9.36-2.352 1.584-2.832 2.304-9.696-3.792-11.568z"
        fill="#FCD9A4"
      />
      <path
        d="M99.96 57.772c-1.584-1.104-1.776-3.168-1.728-4.08-.912-.096-2.736-.432-3.408-1.872-.24-.576-.096-.96.048-1.2.096-.144.24-.24.384-.384a2.663 2.663 0 01-.384-.288c-.576-.576-.48-1.488.144-1.968.144-.096.288-.192.48-.288-.672-.528-1.056-1.392-.48-2.064.288-.336.864-.528 1.44-.672-1.104-.72-1.152-1.536-.768-2.16.96-1.488 7.44-.432 10.176.096.24.048.432.288.384.576a.498.498 0 01-.576.384c-4.512-.864-8.784-1.104-9.168-.48-.432.672 1.44 1.392 2.208 1.584.24.048.384.288.336.528-.048.24-.288.432-.528.384-.624-.048-2.976 0-2.832.576.096.576 1.056.912 1.92 1.008.432 0 .768.096.816.096.24.048.432.288.384.528-.048.24-.24.432-.48.432-.096 0-.432 0-.864-.096-.72-.048-1.536.048-1.872.288a.342.342 0 00-.048.528c.288.288 1.008.432 1.728.528.48 0 .864 0 1.104.048.24 0 .432.24.432.48s-.192.48-.48.48h-1.056c-.768.048-1.488.144-1.68.384-.048.048 0 .144.048.24.624 1.344 3.024 1.344 3.024 1.344.24 0 .528.24.48.528 0 .048-.288 2.592 1.296 3.696.24.144.288.432.096.672-.096.24-.384.288-.576.144z"
        fill="#112C44"
      />
      <path
        d="M92.568 42.076c-.336 1.92-2.16 3.504-4.08 3.504s-3.216-1.584-2.928-3.504c.336-1.92 2.16-3.504 4.08-3.504 1.92.048 3.216 1.584 2.928 3.504z"
        fill="#8DCAFF"
      />
      <path
        d="M92.568 42.076c-.336 1.92-2.16 3.504-4.08 3.504-1.344 0-2.352-.72-2.784-1.824.432.288 1.008.432 1.632.432 1.92 0 3.744-1.584 4.08-3.504.096-.624.048-1.2-.144-1.68.96.624 1.488 1.776 1.296 3.072z"
        fill="#75BFFF"
      />
      <path
        d="M65.592 16.156c.288 3.264.096 5.328-1.008 8.016l-5.04-8.784 6.048.768z"
        fill="#112C44"
      />
      <path
        d="M59.016 40.444c-6.624.384-13.248-7.248-13.248-7.248v-.144 9.072c0 9.504 13.296 9.504 13.296 0l-.048-1.68z"
        fill="#FCD9A4"
      />
      <path
        d="M52.392 49.708c-3.168 0-6.672-2.112-7.104-6.72 0-.288.192-.48.432-.528.288-.048.48.192.528.432.336 4.08 3.36 5.856 6.192 5.856 2.832 0 5.808-1.872 6.096-5.952 0-.288.24-.48.528-.432.288 0 .48.24.432.528-.384 4.656-3.936 6.768-7.104 6.816z"
        fill="#112C44"
      />
      <path
        d="M64.632 31.756c-.384 4.224-1.632 8.064-4.896 8.592-6.864 1.056-14.016-7.152-14.016-7.152s.144-2.4-.144-3.12c-.336-.912-1.44-1.632-1.968-3.408-.432-1.44-.288-2.928 1.68-3.696l.768-6.624 17.952 3.408s.672 3.792.72 8.112c.048 1.296.048 2.592-.096 3.888z"
        fill="#FCD9A4"
      />
      <path
        d="M63.576 20.668c-4.704 2.064-14.112-1.344-14.112-1.344 1.776 4.08.192 7.296.192 7.296-.576 1.056-1.2 1.2-1.824 0-.528-3.504-1.344-3.648-2.4-3.456-1.584.24-1.968 1.392-2.016 2.448 0 0-.48-1.296-1.056-3.024a41.515 41.515 0 01-1.056-3.696c-.336-1.344-.528-2.544-.432-3.36.192-1.92 1.344-1.968 1.92-1.824.24.048.384.096.384.096.672-6.96 5.856-3.648 5.856-3.648.288-3.888 15.744-4.8 18.192.816.432.96.672 1.872.816 2.688v.192c.48 3.984-2.448 5.904-4.464 6.816zm-6.288 5.904c.048.48-.24.864-.576.864-.336 0-.624-.336-.672-.816-.048-.48.24-.864.576-.864.384 0 .672.336.672.816zm6.624.144c.048.48-.24.864-.576.864-.336 0-.624-.336-.672-.816-.048-.48.24-.864.576-.864.336-.048.672.336.672.816z"
        fill="#112C44"
      />
      <path
        d="M57.384 34.012c-.144-.24-.048-.528.192-.672.24-.144.528-.048.672.192.096.192 1.152 1.776 3.168.672.24-.144.528-.048.672.192.144.24.048.528-.192.672-1.968 1.056-3.744.336-4.512-1.056zm1.632 6.432s-3.888.144-7.776-2.112c-.24-.144-.528.096-.432.336 1.008 2.88 3.792 4.032 8.208 3.888v-2.112z"
        fill="#E2AD5D"
      />
      <path
        d="M46.633 29.462c1.42-.33 2.227-2.082 1.802-3.916-.425-1.834-1.921-3.053-3.341-2.724-1.42.33-2.228 2.083-1.803 3.916.425 1.834 1.921 3.053 3.342 2.724z"
        fill="#FCD9A4"
      />
      <path
        d="M58.44 24.172c0 .288-1.104.576-2.16.576s-1.632-.24-1.632-.576c0-.288.576-.528 1.632-.528s2.16.24 2.16.528zm6.576 0c0 .288-1.104.576-2.16.576s-1.632-.24-1.632-.576c0-.288.576-.528 1.632-.528 1.008 0 2.16.24 2.16.528z"
        fill="#112C44"
      />
      <path
        d="M60.216 30.364H59.4c-.288 0-.48-.192-.48-.48s.192-.48.48-.48h.816c.192 0 .336-.192.288-.336l-.816-3.024c-.048-.24.096-.528.336-.576.24-.048.528.096.576.336l.816 3.024c.096.384.048.768-.192 1.056-.24.336-.576.48-1.008.48zm-14.592-3.84l-.336-.816c-.096-.24 0-.528.24-.624.24-.096.528 0 .624.24l.336.816c.096.24 0 .528-.24.624-.24.096-.528 0-.624-.24z"
        fill="#E2AD5D"
      />
      <path
        d="M32.28 136.06c-.048-.24.144-.48.432-.528 9.552-1.104 12.144-7.92 9.312-15.168a.461.461 0 01.288-.624c.24-.096.528 0 .624.288 3.12 7.92 0 15.264-10.08 16.464-.288 0-.528-.192-.576-.432z"
        fill="#112C44"
      />
    </svg>
  );
};
