export const BackDocIllustration = (props: any) => {
  return (
    <svg
      width={265}
      height={200}
      viewBox="0 0 265 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M236.572 195.811H27.268c-14.043 0-25.428-11.384-25.428-25.427V29.862c0-14.043 11.385-25.427 25.428-25.427h209.304C250.615 4.435 262 15.819 262 29.862v140.521c0 14.044-11.385 25.428-25.428 25.428z"
        fill="#D3EAFF"
      />
      <path
        d="M236.572 4.435H27.268C13.225 4.435 1.84 15.819 1.84 29.863v10.682a5.765 5.765 0 005.765 5.765h248.63a5.764 5.764 0 005.764-5.765V29.862C262 15.819 250.615 4.435 236.572 4.435z"
        fill="#448CCA"
      />
      <rect x={17} y={73} width={228} height={11} rx={5.5} fill="#448CCA" />
      <rect x={17} y={94} width={173} height={11} rx={5.5} fill="#448CCA" />
      <rect x={17} y={115} width={131} height={11} rx={5.5} fill="#448CCA" />
      <rect x={17} y={136} width={131} height={11} rx={5.5} fill="#448CCA" />
    </svg>
  );
};
