import { Box, useMediaQuery, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { memo } from "react";
import { useWindowDimensions } from "../../../../../hooks/useScreenSize";
import { ITasyRegisterContainerProps } from "./models";

export const NonTasyRegisterContainer = memo(
  ({ children }: ITasyRegisterContainerProps) => {
    const theme = useTheme();

    const matches = useMediaQuery(theme.breakpoints.down("md"));

    const { height } = useWindowDimensions();

    return (
      <Box
        sx={{
          height: height > 900 ? "100vh" : "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "auto",
        }}
      >
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
          style={{
            marginBlock: height > 900 ? undefined : 30,
            height: height > 900 ? "85%" : "100%",
            width: matches ? "90%" : "70%",
          }}
        >
          <Box
            sx={(t) => ({
              height: "100%",
              width: "100%",
              display: "flex",
              borderRadius: t.shape.borderRadius,
              boxShadow: t.shadows[1],
            })}
          >
            {children}
          </Box>
        </motion.div>
      </Box>
    );
  }
);
