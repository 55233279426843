import {
  ILoginUser, IUserRegisterByValidation, IUserRegisterResponse,
  IUserRegiter,
  IUsersRefreshTokenPost,
  IUsersTokenPost,
  IUsersTokenPostResponse,
  TEmailConfirmation,
  TResendEmailConfirmation,
  TResetPassword,
  TSendEmail,
} from "../models/user";
import { userService } from "./user.service";
import axios from "axios";
import { tokenService } from "./token.service";
import { jsonToFormData } from "../utils/utils";
import { getDomain } from "./domain.service";
import i18n from "../i18n";

const BASE_URL = process.env.REACT_APP_IDENTIY;
const BASE_APP = process.env.REACT_APP_APP;

export const authApi = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    client_id: process.env.CLIENT_ID,
  },
});

authApi.interceptors.request.use((config) => {
  config.headers.Authorization = `Basic ${btoa(getDomain())}`;
  config.headers.Domain = getDomain();

  return config;
});

const login = (user: ILoginUser) => {
  const grant_type = "password";

  const request: IUsersTokenPost = {
    password: user.password,
    username: user.username,
    grant_type,
    scope: "3",
  };

  return authApi.post<IUsersTokenPostResponse>(
    BASE_URL + "/api/v1/Users/Token",
    jsonToFormData(request)
  );
};

authApi.interceptors.request.use((config) => {
  config.headers["Accept-Language"] = i18n.language;

  return config;
});

const refresh_token = () => {
  const grant_type = "refresh_token";
  const refresh_token = tokenService.getTokens().refresh_token;

  const request: IUsersRefreshTokenPost = {
    refresh_token: refresh_token || "",
    username: userService.getUserName() || "",
    grant_type,
    establishment_id: userService.getEstablishmentId() || "",
    scope: "3",
  };

  return authApi.post<IUsersTokenPostResponse>(
    BASE_URL + "/api/v1/users/Refresh",
    jsonToFormData(request)
  );
};

const register = (user: IUserRegiter) => {
  return authApi.post<IUserRegisterResponse>(BASE_APP + "/api/v1/Register/Register", user);
};

const registerByValidation = (user: IUserRegisterByValidation) => {
    return authApi.post<IUserRegisterResponse>(
        BASE_APP + "/api/v1/Register/RegisterByValidation",
        user
    );
}

const tokenByToken = (establishmentId: string) => {
  return authApi.post<IUsersTokenPostResponse>(
    BASE_URL + "/api/v1/Users/TokenByToken",
    {
      establishmentId,
    }
  );
};

const sendEmail = (params: TSendEmail) => {
  return authApi.post(BASE_URL + "/api/v1/Users/ForgotPassword", {
    userName: params.userName,
    softwareCode: params.softwareCode,
  });
};

const resetPassword = (params: TResetPassword) => {
  return authApi.post(BASE_URL + "/api/v1/Users/ResetPassword", params);
};

const emailConfirmation = (params: TEmailConfirmation) => {
  return authApi.post(BASE_URL + "/api/v1/Users/EmailConfirmation", {
    username: params.userName,
    confirmationCode: params.code,
  });
};

const resendEmailConfirmation = (params: TResendEmailConfirmation) => {
  return authApi.post(
    BASE_URL + "/api/v1/Users/ResendEmailConfirmation",
    params
  );
};

const getEIFromUrl = (): number | undefined => {
  const splitedUrl = window.location.href.split("/");
  const eIndex = splitedUrl.findIndex((u) => u === "e");

  if (eIndex === -1) {
    return;
  }
  return Number(splitedUrl[eIndex + 1]);
};

export const authService = {
  login,
  refresh_token,
  sendEmail,
  resetPassword,
  tokenByToken,
  getEIFromUrl,
  register,
  registerByValidation,
  emailConfirmation,
  resendEmailConfirmation,
};
