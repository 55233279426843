import {
  Button,
  FormTakePhotoInput,
  InputFileForm,
  Modal,
  useFormContext,
} from "@4uhub/lib4uhub";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { Box } from "@mui/material";
import { memo } from "react";
import { useWatch } from "react-hook-form";
import { authApi } from "../../../../../../../../../services/auth.service";
import { TFormNonTasy } from "../../../formNonTasySchema";
import { FieldContainer } from "./FieldContainer/FieldContainer";
import { Masks } from "./Masks/Masks";
import { IPhotoModalProps } from "./models";

const FILE_ROUTE = process.env.REACT_APP_FILEMANAGER + "/api/v1/Upload";

export const PhotoModal = memo(
  ({
    photo_name,
    file_name,
    label,
    open,
    type,
    mediaTypeCode,
    onClose,
  }: IPhotoModalProps) => {
    const { control } = useFormContext<TFormNonTasy>();

    const photo = useWatch({ name: photo_name, control });

    const file = useWatch({ name: file_name!, control });

    const currentFile = file_name ? file : null;

    const buttonDisabled = !photo && !currentFile;

    return (
      <Modal open={open} onClose={onClose} title={label}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {file_name && (
            <FieldContainer
              label="Anexe um arquivo"
              icon={AttachFileRoundedIcon}
            >
              <InputFileForm
                name={file_name}
                axios={authApi}
                fileRoute={FILE_ROUTE}
                disable={!!photo}
                fileInputProps={{
                  accept: ".jpg, .jpeg, .png, .pdf",
                  label: "Anexar arquivo",
                  maxFileSize: 5,
                  mediaTypeCode: mediaTypeCode,
                  mediaTypeIdentifier: "IndividualValidationDocMediaType",
                  parentName: "IndividualValidationDoc",
                  type: "Private",
                  inputProps: {
                    size: "small",
                  },
                }}
              />
            </FieldContainer>
          )}
          <FieldContainer label="Tire uma foto" icon={CameraAltRoundedIcon}>
            <FormTakePhotoInput
              name={photo_name}
              axios={authApi}
              fileRoute={FILE_ROUTE}
              disabled={!!currentFile}
              uploadParams={{
                mediaTypeCode: mediaTypeCode,
                mediaTypeIdentifier: "IndividualValidationDocMediaType",
                parentName: "IndividualValidationDoc",
                type: "Private",
              }}
              mask={<Masks type={type} />}
            />
          </FieldContainer>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              size="small"
              variant="contained"
              disabled={buttonDisabled}
              startIcon={<CheckRoundedIcon />}
              onClick={onClose}
            >
              Confirmar
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  }
);
