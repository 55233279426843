import { useFormContext } from "@4uhub/lib4uhub";
import {
  Box,
  FormHelperText,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { TFormNonTasy } from "../formNonTasySchema";
import { Photo } from "./Photo/Photo";

const translationPath = "components.nonTasyRegister.photosSection.";

export const PhotosSection = memo(() => {
  const { t } = useTranslation();

  const {
    formState: { errors },
  } = useFormContext<TFormNonTasy>();

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const photosError = errors.photos ? errors.photos.message : null;

  return (
    <Grid item container xs={12} sm={12} spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography fontWeight="bold">
          {t(`${translationPath}title`)}
        </Typography>
      </Grid>
      <Photo
        photo_name="selfiePhoto"
        label={t(`${translationPath}selfie`)}
        type="self"
        mediaTypeCode="1"
      />
      <Photo
        photo_name="selfieDocPhoto"
        label={t(`${translationPath}selfie_doc`)}
        type="self-doc"
        mediaTypeCode="4"
      />
      <Photo
        photo_name="frontDocPhoto"
        file_name="frontDoc"
        label={t(`${translationPath}front_doc`)}
        type="front-doc"
        mediaTypeCode="2"
      />
      <Photo
        photo_name="backDocPhoto"
        file_name="backDoc"
        label={t(`${translationPath}back_doc`)}
        type="back-doc"
        mediaTypeCode="3"
      />
      {photosError && (
        <Box sx={{ mx: 2, mt: 1 }}>
          <FormHelperText sx={(t) => ({ m: 0, color: t.palette.error.main })}>
            {t(photosError)}
          </FormHelperText>
        </Box>
      )}
      <Box sx={{ mx: 2, mt: 1, mb: matches ? 2 : 1 }}>
        <Typography
          variant="subtitle2"
          sx={(t) => ({
            lineHeight: 1.2,
            color: t.palette.grey[t.palette.mode === "light" ? 600 : 400],
          })}
        >
          {t(`${translationPath}helper`)}
        </Typography>
      </Box>
    </Grid>
  );
});
