import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { IFieldContainerProps } from "./models";

export const FieldContainer = memo(
  ({ label, icon: Icon, children }: IFieldContainerProps) => {
    return (
      <Box
        sx={(t) => ({
          p: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          border: `1px dashed ${t.palette.divider}`,
          borderRadius: t.shape.borderRadius,
        })}
      >
        <Box sx={{ mb: 3, display: "flex", flexDirection: "row", gap: 1 }}>
          <Icon color="action" />
          <Typography fontWeight="bold">{label}</Typography>
        </Box>
        {children}
      </Box>
    );
  }
);
