import { RegisterProvider } from "../../store/contexts/RegisterContext/RegisterContext";
import { RegisterContainer } from "./RegisterContainer/RegisterContainer";

const RegisterPage = () => {
  return (
    <RegisterProvider>
      <RegisterContainer />
    </RegisterProvider>
  );
};

export default RegisterPage;
