import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

interface IFormStepperContext {
  activeStep: number;
  initialStep?: number;
  handleNext: () => void;
  handleBack: () => void;
}

interface IFormStepperContextProviderProps {
  children: JSX.Element;
  initialStep?: number;
  currentActiveStep?: number;
}

const FormStepperContext = createContext<IFormStepperContext>({
  activeStep: 1,
  handleNext: () => {},
  handleBack: () => {},
});

export const FormStepperContextProvider: React.FC<
  IFormStepperContextProviderProps
> = ({ currentActiveStep, initialStep = 1, children }) => {
  const [activeStep, setActiveStep] = useState(initialStep);

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  useEffect(() => {
    if (currentActiveStep !== undefined) {
      setActiveStep(currentActiveStep);
    }
  }, [currentActiveStep]);

  return (
    <FormStepperContext.Provider
      value={{
        activeStep,
        initialStep,
        handleNext,
        handleBack,
      }}
    >
      {children}
    </FormStepperContext.Provider>
  );
};

export const useFormStepperContext = () => {
  const context = useContext(FormStepperContext);

  return context;
};
