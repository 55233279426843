import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import HowToRegRoundedIcon from "@mui/icons-material/HowToRegRounded";
import { Box } from "@mui/material";
import { memo, useCallback } from "react";
import { useRegisterContext } from "../../../../../../../store/contexts/RegisterContext/RegisterContext";
import Button from "../../../../../../UI/Button";
import {useTranslation} from "react-i18next";
import {IActionsProps} from "./models";

const translationPath = "components.nonTasyRegister.actions."

export const Actions = memo(({ loading }: IActionsProps) => {
  const { t } = useTranslation()

  const { onChangeTasyRegister } = useRegisterContext();

  const backHandler = useCallback(() => {
    onChangeTasyRegister(true);
  }, [onChangeTasyRegister]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "end",
        gap: 2,
      }}
    >
      <Button
        size="small"
        endIcon={<ArrowBackRoundedIcon />}
        disabled={loading}
        onClick={backHandler}
      >
          {t(`${translationPath}back`)}
      </Button>
      <Button
        size="small"
        variant="contained"
        type="submit"
        endIcon={<HowToRegRoundedIcon />}
        loading={loading}
      >
          {t(`${translationPath}register`)}
      </Button>
    </Box>
  );
});
