import { memo } from "react";
import { useTranslation } from "react-i18next";
import AuthCardContainer from "../../../components/Auth/AuthCardContainer/AuthCardContainer";
import { NonTasyRegisterForm } from "../../../components/Auth/RegisterForm/NonTasyRegisterForm/NonTasyRegisterForm";
import RegisterForm from "../../../components/Auth/RegisterForm/RegisterForm";
import { useRegisterContext } from "../../../store/contexts/RegisterContext/RegisterContext";

export const RegisterContainer = memo(() => {
  const { t } = useTranslation();

  const { tasyRegister } = useRegisterContext();

  if (!tasyRegister) {
    return <NonTasyRegisterForm />;
  }

  return (
    <AuthCardContainer
      link="/login"
      textLink={
        t("page.register.has_register") ||
        "Already registered? Access your account"
      }
    >
      <RegisterForm />
    </AuthCardContainer>
  );
});
