import { createContext, useCallback, useContext, useState } from "react";
import { IUserRegiter } from "../../../models/user";

export type TRegisterIllustration =
  | "default"
  | "front-doc"
  | "back-doc"
  | "self"
  | "self-doc";

interface IRegisterContext {
  tasyRegister: boolean;
  openHelperModal: boolean;
  userRegister: IUserRegiter | null;
  illustrationRegister: TRegisterIllustration;
  onChangeTasyRegister: (value: boolean) => void;
  onChangeIllustrationRegister: (value: TRegisterIllustration) => void;
  onGetUserRegisterData: (value: IUserRegiter) => void;
  onOpenHelperModal: () => void;
  onCloseHelperModal: () => void;
}

interface IRegisterProviderProps {
  children: JSX.Element;
}

const RegisterContext = createContext<IRegisterContext>({
  tasyRegister: true,
  openHelperModal: false,
  userRegister: null,
  illustrationRegister: "default",
  onChangeTasyRegister: () => {},
  onChangeIllustrationRegister: () => {},
  onGetUserRegisterData: () => {},
  onOpenHelperModal: () => {},
  onCloseHelperModal: () => {},
});

export const RegisterProvider: React.FC<IRegisterProviderProps> = ({
  children,
}) => {
  const [openHelperModal, setOpenHelperModal] = useState(false);

  const [tasyRegister, setTasyRegister] = useState(true);

  const [userRegister, setUserRegister] = useState<IUserRegiter | null>(null);

  const [illustrationRegister, setIllustrationRegister] =
    useState<TRegisterIllustration>("default");

  const onChangeTasyRegister = useCallback((value: boolean) => {
    setTasyRegister(value);
  }, []);

  const onChangeIllustrationRegister = useCallback(
    (value: TRegisterIllustration) => {
      setIllustrationRegister(value);
    },
    []
  );

  const onGetUserRegisterData = useCallback((value: IUserRegiter) => {
    setUserRegister(value);
  }, []);

  const onOpenHelperModal = useCallback(() => {
    setOpenHelperModal(true);
  }, []);

  const onCloseHelperModal = useCallback(() => {
    setOpenHelperModal(false);
  }, []);

  return (
    <RegisterContext.Provider
      value={{
        tasyRegister,
        openHelperModal,
        userRegister,
        illustrationRegister,
        onChangeTasyRegister,
        onChangeIllustrationRegister,
        onGetUserRegisterData,
        onOpenHelperModal,
        onCloseHelperModal,
      }}
    >
      {children}
    </RegisterContext.Provider>
  );
};

export const useRegisterContext = () => {
  const context = useContext(RegisterContext);

  return context;
};
