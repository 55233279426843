import {
  Form,
  FormInput,
  useFetch,
  useNotificationContext,
} from "@4uhub/lib4uhub";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Grid, Typography } from "@mui/material";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useWindowDimensions } from "../../../../../../hooks/useScreenSize";
import { authService } from "../../../../../../services/auth.service";
import { useRegisterContext } from "../../../../../../store/contexts/RegisterContext/RegisterContext";
import { Actions } from "./Actions/Actions";
import { PhotosSection } from "./PhotosSection/PhotosSection";
import { formNonTasySchema, TFormNonTasy } from "./formNonTasySchema";

const translationPath = "components.nonTasyRegister.";

export const FormNonTasy = () => {
  const { t } = useTranslation();

  const { setMessage } = useNotificationContext();

  const { userRegister } = useRegisterContext();

  const navigate = useNavigate();

  const { height } = useWindowDimensions();

  const methods = useForm<TFormNonTasy>({
    resolver: zodResolver(formNonTasySchema),
  });

  const { handleSubmit } = methods;

  const { sendRequest, loading } = useFetch(authService.registerByValidation);

  const onSubmitHandler = useCallback(
    async (values: TFormNonTasy) => {
      if (!userRegister) return;

      let filesId: string[] = [];

      filesId.push(
        values.frontDocPhoto ?? (values.frontDoc ? values.frontDoc.id : "")
      );
      filesId.push(
        values.backDocPhoto ?? (values.backDoc ? values.backDoc.id : "")
      );
      filesId.push(values.selfiePhoto ?? "");
      filesId.push(values.selfieDocPhoto ?? "");

      const { data, success } = await sendRequest({
        ...userRegister,
        ...values,
        document: userRegister.document ?? "",
        birthDate: userRegister.birthDate ?? "",
        mediaFilesIds: filesId,
      });

      if (data && success) {
        setMessage({
          message: t(`${translationPath}success`),
          type: "success",
        });
        sessionStorage.setItem("registered", "true");
        navigate(`/email-confirmation/${userRegister.document}`);
      }
    },
    [navigate, sendRequest, userRegister, setMessage, t]
  );

  return (
    <Form {...methods}>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitHandler)}
        sx={{
          mt: 2,
          height: height < 900 ? "100vh" : "100%",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflowY: loading ? "hidden" : "auto",
        }}
      >
        <Typography variant="h5" textAlign="center">
          {t(`${translationPath}finish_register`)}
        </Typography>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={12}>
              <FormInput
                name="name"
                label={t(`${translationPath}name`)}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormInput
                name="whatsappNumber"
                label={t(`${translationPath}whatsapp_number`)}
                size="small"
                fullWidth
              />
            </Grid>
            <PhotosSection />
          </Grid>
          <Actions loading={loading} />
        </Box>
      </Box>
    </Form>
  );
};
