import { Modal, useFormContext } from "@4uhub/lib4uhub";
import AddAPhotoRoundedIcon from "@mui/icons-material/AddAPhotoRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import {
  Box,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { memo, useCallback, useState } from "react";
import { useWatch } from "react-hook-form";
import { useRegisterContext } from "../../../../../../../../store/contexts/RegisterContext/RegisterContext";
import { IllustrationSection } from "../../../../IllustrationSection/IllustrationSection";
import { TFormNonTasy } from "../../formNonTasySchema";
import { IPhotoProps } from "./models";
import { PhotoModal } from "./PhotoModal/PhotoModal";

export const Photo = memo(
  ({ photo_name, file_name, label, type, mediaTypeCode }: IPhotoProps) => {
    const {
      openHelperModal,
      onOpenHelperModal,
      onCloseHelperModal,
      onChangeIllustrationRegister,
    } = useRegisterContext();

    const { control } = useFormContext<TFormNonTasy>();

    const theme = useTheme();

    const matches = useMediaQuery(theme.breakpoints.down("md"));

    const [open, setOpen] = useState(false);

    const photo = useWatch({ name: photo_name, control });

    const file = useWatch({ name: file_name!, control });

    const currentFile = file_name ? file : null;

    const hasSent = !!photo || !!currentFile;

    const helpHandler = useCallback(() => {
      onChangeIllustrationRegister(type);
      if (!matches) return;
      onOpenHelperModal();
    }, [onChangeIllustrationRegister, onOpenHelperModal, matches, type]);

    const closeHandler = useCallback(() => {
      setOpen(false);
    }, []);

    const openHandler = useCallback(() => {
      setOpen(true);
    }, []);

    return (
      <>
        <Modal open={openHelperModal} onClose={onCloseHelperModal}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IllustrationSection width="100%" />
          </Box>
        </Modal>
        <PhotoModal
          photo_name={photo_name}
          file_name={file_name}
          label={label}
          type={type}
          open={open}
          onClose={closeHandler}
          mediaTypeCode={mediaTypeCode}
        />
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            gap: 1,
          }}
        >
          <Box
            sx={(t) => ({
              width: "100%",
              height: 120,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
              border: hasSent ? undefined : `1px dashed ${t.palette.divider}`,
              borderRadius: t.shape.borderRadius,
              transition: "all 0.3s ease-in-out",
              backgroundColor: hasSent ? t.palette.success.light : undefined,
              cursor: "pointer",
              "&:hover": !hasSent && {
                border: "transparent",
                boxShadow: "0px 0px 21px -8px rgba(0,0,0,0.1)",
              },
            })}
            onClick={openHandler}
          >
            {hasSent ? (
              <CheckCircleRoundedIcon
                sx={{ color: "white" }}
                fontSize="large"
              />
            ) : (
              <AddAPhotoRoundedIcon color="primary" fontSize="large" />
            )}
            <Typography
              sx={(t) => ({
                textAlign: "center",
                color: hasSent ? "white" : t.palette.text.primary,
              })}
            >
              {hasSent ? "Foto enviada" : label}
            </Typography>
          </Box>
          <Tooltip title="Ajuda">
            <InfoRoundedIcon
              color="action"
              sx={{ cursor: "pointer" }}
              onClick={helpHandler}
            />
          </Tooltip>
        </Grid>
      </>
    );
  }
);
