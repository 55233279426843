import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { IIllustrationProps } from "./models";

export const Illustration = memo(
  ({ title, subtitle, illustration: Illustration }: IIllustrationProps) => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <Illustration />
        <Box
          sx={{
            mx: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <Typography
            variant="h4"
            sx={(t) => ({
              textAlign: "center",
              fontWeight: "bold",
              lineHeight: 1,
              color: t.palette.getContrastText(t.palette.primary.main),
            })}
          >
            {title}
          </Typography>
          <Typography
            sx={(t) => ({
              textAlign: "center",
              color: t.palette.getContrastText(t.palette.primary.main),
            })}
          >
            {subtitle}
          </Typography>
        </Box>
      </Box>
    );
  }
);
