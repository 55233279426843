import { Box } from "@mui/material";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FirstStep } from "../components/EvaluationForm/FirstStep/FirstStep";
import { FormNotFound } from "../components/EvaluationForm/FormNotFound/FormNotFound";
import { FormSucceeded } from "../components/EvaluationForm/FormSucceeded/FormSucceeded";
import { Step } from "../components/EvaluationForm/Step/Step";
import Loading from "../components/Loading";
import { FormsStepper } from "../components/UI/FormsStepper/FormsStepper";
import Header from "../components/UI/Menus/Header/Header";
import useFetch from "../hooks/useFetch";
import { IFormSatisfaction } from "../models/form-satisfaction";
import { evaluationFormQuestions } from "../services/evaluationForm.service";
import { EvaluationFormProvider } from "../store/contexts/EvaluationFormContext";

const EvaluationFormPage = () => {
  const { id } = useParams();

  const [form, SetForm] = useState<IFormSatisfaction>();

  const [formStarted, setFormStarted] = useState(false);

  const [initialStep, setInitialStep] = useState<number | undefined>(undefined);

  const [viewAnswers, setViewAnswers] = useState(false);

  const startFormHandler = useCallback(() => {
    setFormStarted(true);
  }, []);

  const goToStartHandler = useCallback(() => {
    setFormStarted(true);
    setInitialStep(0);
    setViewAnswers(true);
  }, []);

  const resetInitialStep = useCallback(() => {
    setInitialStep(undefined);
  }, []);

  const { sendRequest, loading } = useFetch(evaluationFormQuestions);

  const fetchEvaluationForm = useCallback(async () => {
    if (!id) return;
    const { data, success } = await sendRequest(id);
    if (data && success) {
      SetForm(data);
    }
  }, [sendRequest, id]);

  useEffect(() => {
    fetchEvaluationForm();
  }, [fetchEvaluationForm]);

  const renderFormControl = useCallback(() => {
    const formQuestions = form?.evaluationForm.evaluationFormQuestions || [];

    if (!viewAnswers)
      for (let i = 0; i < formQuestions.length; i++) {
        const question = form?.evaluationForm.evaluationFormQuestions[i];
        if (form?.evaluationForm.evaluationFormQuestions) {
          if (form?.answerDate) {
            setFormStarted(true);
            setInitialStep(formQuestions.length + 2);
            break;
          }
          if (form.evalMovAnswerEncSatisfactions?.length === 0) {
            setInitialStep(0);
            break;
          }
          if (
            form?.evalMovAnswerEncSatisfactions &&
            !form?.evalMovAnswerEncSatisfactions.find(
              (asw) => asw.questionId === question?.question.id
            )
          ) {
            setInitialStep(i);
            setFormStarted(true);
            break;
          } else {
            setInitialStep(0);
          }
        }
      }
  }, [
    form?.answerDate,
    form?.evalMovAnswerEncSatisfactions,
    form?.evaluationForm.evaluationFormQuestions,
    viewAnswers,
  ]);

  useLayoutEffect(() => {
    renderFormControl();
  }, [renderFormControl]);

  useEffect(() => {
    return () => {
      setViewAnswers(false);
    };
  }, []);

  if (loading)
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </Box>
    );

  return (
    <EvaluationFormProvider
      initialAnswers={
        form
          ? form?.evalMovAnswerEncSatisfactions
            ? form?.evalMovAnswerEncSatisfactions
            : []
          : []
      }
    >
      <Box sx={{ width: "100%", height: "100vh" }}>
        <Header alwaysShowLogo accessPortalBtn />
        {!form && <FormNotFound />}
        {form && !formStarted && (
          <FirstStep
            evaluationForm={form.evaluationForm}
            onStartForm={startFormHandler}
          />
        )}
        {form && formStarted && (
          <FormsStepper
            endComponent={
              <FormSucceeded
                onGoToStart={goToStartHandler}
                onResetInitialStep={resetInitialStep}
              />
            }
            initialStep={initialStep}
            currentActiveStep={initialStep}
            steps={[
              ...form.evaluationForm.evaluationFormQuestions.map(
                (stepForm, index) => ({
                  component: (
                    <Step
                      question={stepForm.question}
                      stepsLength={
                        form.evaluationForm.evaluationFormQuestions.length + 1
                      }
                      hasAnswerDate={!!form.answerDate || viewAnswers}
                    />
                  ),
                  step: index,
                })
              ),
            ]}
          />
        )}
      </Box>
    </EvaluationFormProvider>
  );
};

export default EvaluationFormPage;
