import { Box, useMediaQuery, useTheme } from "@mui/material";
import { memo } from "react";
import { FormNonTasy } from "./FormNonTasy/FormNonTasy";
import { Header } from "./Header/Header";

export const NonTasyRegister = memo(() => {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        px: 4,
        py: 2,
        width: matches ? "50%" : "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Header />
      <FormNonTasy />
    </Box>
  );
});
