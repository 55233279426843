export const SelfieIllustration = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 450 607" {...props}>
      <g
        style={{
          isolation: "isolate",
        }}
        className="color000000 svgShape"
      >
        <g fill="#000" className="color000000 svgShape">
          <path
            d="M76.48 208.69c-.28-.84-9.55.84-10.67 14.61s14.33 18 14.33 18zm5.9 60.4l-1.85 24.83-.81 10.85s-25.15 0-29.92-21.35a13.24 13.24 0 01-.09-6.31c3.43-13.52 32.67-8.02 32.67-8.02z"
            fill="#ffd392"
            className="colorffb592 svgShape"
          />
          <path
            d="M80.53 293.92l-.81 10.85s-25.15 0-29.92-21.35a13.24 13.24 0 01-.09-6.31c4.89 6.09 14.18 14.81 28.18 16.42q1.44.17 2.64.39z"
            style={{
              mixBlendMode: "multiply",
            }}
            fill="#ffd392"
            opacity={0.33}
            className="colorffb592 svgShape"
          />
          <path
            d="M80.14 313.62l-.54 21.32-.31 12.39S54 341.43 51.9 327.52a15.08 15.08 0 011-8.93c3.49-7.05 13.72-9.06 27.24-4.97z"
            fill="#ffd392"
            className="colorffb592 svgShape"
          />
          <path
            d="M79.6 334.94l-.31 12.39S54 341.43 51.9 327.52a15.08 15.08 0 011-8.93c4.84 5.13 15.71 15.4 25.8 16.24a8 8 0 01.9.11z"
            style={{
              mixBlendMode: "multiply",
            }}
            fill="#ffd392"
            opacity={0.33}
            className="colorffb592 svgShape"
          />
          <path
            d="M338.59 569.18c-1.71 1.17-3.26 2.17-4.6 2.93-2.27 1.31-3.94 2-4.8 1.85-11.06-1.7-23.62 3.08-37.45-22.59-1.66-3.08-3.42-6.13-5.32-9.11a100.61 100.61 0 00-10.76-14.16c-2.6-2.81-5.41-6-8.39-9.59v-.06c-27.31-32.59-68.4-93.18-82.58-129.13-15.73-39.88 36.48-45.81 59.54-29.65 0 0 14.63-6.87 18.56-26.53L261 275.65l-.84-28.65-.26-8.64-1.09-36.67s28.65 42.7 37.08 46.07S329 273 336.34 305.61 353.76 407.87 365 430.34s31.08 73.44 35.58 82.43c1.7 3.4-41.77 42.58-61.99 56.41z"
            fill="#ffd392"
            className="colorffb592 svgShape"
          />
          <path
            d="M334 572.11c-2.27 1.31-3.94 2-4.8 1.85-11.06-1.7-23.62 3.08-37.45-22.59-1.66-3.08-3.42-6.13-5.32-9.11a100.61 100.61 0 00-10.76-14.16c-2.6-2.81-5.41-6-8.39-9.59v-.06c-27.31-32.59-68.4-93.18-82.58-129.13-15.73-39.88 36.48-45.81 59.54-29.65 0 0 14.63-6.87 18.56-26.53l-1.8-57.49c6 7.47 21.94 30 21.94 56.93 0 33.15-8.42 44.95-8.42 52.25s15.73 29.78 14 61.8c-.95 19.23 15.79 81.9 45.48 125.48z"
            style={{
              mixBlendMode: "multiply",
            }}
            opacity={0.48}
            fill="#ffd392"
            className="colorffb592 svgShape"
          />
          <path
            d="M251.62 50.7l10.14 305.85a18.28 18.28 0 01-17.67 18.89l-146.95 4.87a18.28 18.28 0 01-18.88-17.67L68.11 56.79a18.28 18.28 0 0117.67-18.88l147-4.88a18.29 18.29 0 0118.84 17.67z"
            fill="#1d5d95"
            className="color1d4d95 svgShape"
          />
          <path
            d="M217.81 33.52c-16.32 15-40.59 42.56-45.84 77.54-8.07 53.76 11.24 84.09-17.38 112.5-28.22 28-16.1 137.47-34.14 156l-23.31.77a18.28 18.28 0 01-18.88-17.67L68.11 56.79a18.28 18.28 0 0117.67-18.88z"
            style={{
              mixBlendMode: "screen",
            }}
            fill="#1d5d95"
            className="color1d4d95 svgShape"
          />
          <path
            d="M246.79 60.85l9.49 285.89a12.52 12.52 0 01-12.1 12.93l-147.46 4.9a12.53 12.53 0 01-12.93-12.11L74.3 66.58v-.14a12.52 12.52 0 0112.1-12.8l147.46-4.89a12.53 12.53 0 0112.93 12z"
            fill="#fff"
            className="colorffffff svgShape"
          />
          <path
            d="M98.13 170.77l.25-2.76s-.07-9.9.21-12 .3-3.8 1.57-4.45a69.46 69.46 0 0110.1-2.74c1 0 3.78.56 4.07 1.24s-1.18 6.24-.71 7.35 1.65.29 1.65.29a23.76 23.76 0 012.28-3.87c.59-.45 1.22-2.19 1.89-2.91s1-2.18 2.43-2-.6 5.27-.6 5.27-2.29 6.37-3.1 7.86-3.18 5.36-4.54 6.09-2.71 1.3-3.11 2.09-1.21 2.71-1.21 2.71-11.14-.79-11.18-2.17z"
            fill="#ffd392"
            className="colorffb592 svgShape"
          />
          <path
            d="M110 149.13l.62-3 1.2-4.29 1.2-3.22s1.35-3.14 2.25-3.67 1.73-.18 1.73 1.05-1.48 5.22-1.51 5.33-.86 4.13-.86 4.13l-.33 5s-5.03-.58-4.3-1.33z"
            fill="#ffd392"
            className="colorffb592 svgShape"
          />
          <path
            d="M106.4 150.17l.58-6.85 1.2-5.33 1.75-4.53s1.07-2.34 2.13-1.51 0 5 0 5-.55 4-.75 5-.69 7-.67 7.37-3.76 1.47-4.24.85z"
            fill="#ffd392"
            className="colorffb592 svgShape"
          />
          <path
            d="M102.65 150.82l.53-6.31 1.11-4.9 1.6-4.18s1-2.14 2-1.38 0 4.6 0 4.6-.51 3.66-.69 4.62-.63 6.47-.62 6.79-3.49 1.33-3.93.76z"
            fill="#ffd392"
            className="colorffb592 svgShape"
          />
          <path
            d="M99.12 153.12l.52-6.31 1.1-4.9 1.61-4.18s1-2.14 2-1.38 0 4.6 0 4.6-.51 3.66-.69 4.62-.66 6.43-.66 6.79-3.44 1.33-3.88.76zm85.78 9.03c0 .16-2.89 10.43-12.81 15.46s-24.29-.94-18.76-11.66l2.59-.81a3 3 0 001.23-1.85 33.68 33.68 0 00.26-4.92s19.57-7.45 22.64-11.08v.57c0 2.4 0 11.48.61 12.59.64 1.28 4.24 1.7 4.24 1.7z"
            fill="#ffd392"
            className="colorffb592 svgShape"
          />
          <path
            d="M211.28 320.18l-34.59 1.15s-.38-2-.87-4.48c-.76-3.86-1.78-9-2-9.9-.36-1.41-3.57-1.13-5-4.24a6.55 6.55 0 01-.34-.92c-1.17-4.1-1.3-18.56-1.3-18.56l30.58-4.58 10.9-1.62c.12 4.13 2.62 43.15 2.62 43.15z"
            fill="#1d5d95"
            className="color1d4d95 svgShape"
          />
          <path
            d="M188.62 166.74c.08 2.58-24.27 73.34-25.6 74.93s-12.61-27-14.66-41.9.93-32.7 5-33.81c0 0-2.48 10.13 7.89 11.16 7.9.79 17.5-8.3 21.63-12.68 1.3-1.38 2.05-2.29 2.05-2.29a10.62 10.62 0 011.53 1.07 5.3 5.3 0 012.16 3.52z"
            fill="#ebf6ff"
            className="colorf0ebff svgShape"
          />
          <path
            d="M198.17 320.62c.47-5 .81-12.83.71-15.88-.11-3.27-.27-2.92-5.08-7.75s-4.6-14-4.6-14l8.54-4.36 10.9-1.62c.14 4.13 2.64 43.15 2.64 43.15z"
            style={{
              mixBlendMode: "multiply",
            }}
            fill="#1d5d95"
            opacity={0.46}
            className="color1d4d95 svgShape"
          />
          <path
            d="M174 306.26c0 .34 0 .68-.07 1-.49 6.71-1.48 14.54-1.48 14.54l-34.3 1.2s.27-2.42 1.12-7.79-.06-17.57-.19-21.35.43-18.11.43-18.11c3.34 2 33.42 4.92 33.42 4.92s1.48 18.68 1.07 25.59z"
            fill="#1d5d95"
            className="color1d4d95 svgShape"
          />
          <path
            d="M205.87 247.14c-.06 5.06 1 3.64 1.59 6.38s.47 7.33.55 9.63 2 5 3 8.4-.46 6.9-7 9.42c-2.69 1-8.48 1.85-14.7 2.44a199.88 199.88 0 01-21.48 1.06c-4.38-.32-4.86-1-5.4-3.5s-1.29-38.78-.08-43.87c.18-.73.48-1.81.88-3.14 2.38-8 8.2-25 10.18-30.54 2.31-6.51 11.34-25.18 12.75-31a14.66 14.66 0 00.06-6.69 13.23 13.23 0 00-1.32-3.61c5.77.73 25.41 11.1 28.26 13.77s-2.29 28.1-2.89 30.64c-.38 1.62-.83 4.06-1.45 7.69-.36 2.1-.77 4.6-1.26 7.57-1.34 8.11-1.63 20.29-1.69 25.35z"
            fill="#f39912"
            className="colorf95d6c svgShape"
          />
          <path
            d="M162.86 238.69l-.43 42.31s-1.53 2.35-6.61 1.6-15-2.72-17-6.33-1.24-9.84.22-14.25.47-6.68.83-9.67-.12-3.45.36-9.89-3.33-24-5.15-30.61-3.77-30.43-2.77-34.83 19.15-11.43 21-11a39.45 39.45 0 00-3.75 11.61c-1 6.13 5.49 44 11.13 57.29a15 15 0 002.17 3.77z"
            fill="#f39912"
            className="colorf95d6c svgShape"
          />
          <path
            d="M137 214.51s-.39.06-1 .1c-1.13.07-2.94 0-3.89-.86-1.42-1.33-1.92-2.69-3.47-.8s-7.19 4.83-7.78 7.84-7 6-10 8.48c-1.59 1.33-8.79 6.61-15.32 3.61-6-2.77-7.79-6.28-8.18-11.88 0-.45-.06-.92-.08-1.4-.21-6.42 3.28-32.73 3.44-35s3.13-11.16 4.28-12.8a7.17 7.17 0 013.13-2.4s5.8 1 9.29 1.93a8.74 8.74 0 012.52.89c1 .89.49 7.8.59 10.78s-.25 13.1-.45 14.26c-.16 1 .36 2 1.48.71a6.54 6.54 0 00.59-.78c1.53-2.35 6.71-9 9-10.46s10.33-10.63 12.15-11.15 4.86 31.77 3.7 38.93zm113.61-38.23l1.69 50.94c-2.68 2.16-30 1.46-34.29-2.08a67.67 67.67 0 01-7.51-8c-1.88-2.27-3.23-4.05-3.23-4.05-2.57-22 3.13-37.09 5.89-37.18 1.92-.07 8.17 7.83 11.74 12.57 1.53 2 2.57 3.49 2.57 3.49a.87.87 0 01.22-.29c2.37-2.46 22.92-15.4 22.92-15.4z"
            fill="#f39912"
            className="colorf95d6c svgShape"
          />
          <path
            d="M185.05 138.72c-1 5.08-3.64 4.71-3.64 4.71a6.79 6.79 0 01-.16 1.1 17.45 17.45 0 01-1.72 4.36c-1.44 2.65-8 6.18-12.32 8-.88.38-1.66.69-2.28.9l-.61.2c-3.07.91-7 2.76-10.72-1.25s-7.16-18.37-7.2-30.2 6.6-15.49 6.6-15.49c15.11-9.43 22.63-1.48 26.07 5a33.28 33.28 0 012.18 5.2 15.61 15.61 0 01.62 8.13s.44-.36 1.95 0 2.18 4.25 1.23 9.34z"
            fill="#ffd392"
            className="colorffb592 svgShape"
          />
          <path
            d="M185.05 138.72c-1 5.08-3.64 4.71-3.64 4.71a13.4 13.4 0 01-1.88 5.46c-1.74 3.2-11 7.66-14.6 8.92 3.53-3.05 7.1-7.29 7.57-9.49.6-2.77-1.9-5.33.14-9.77s-1-13.06-1-13.06a8.29 8.29 0 016.46-9.4 3.55 3.55 0 011 0 33.28 33.28 0 012.18 5.2 15.61 15.61 0 01.62 8.13s.44-.36 1.95 0 2.15 4.21 1.2 9.3z"
            style={{
              mixBlendMode: "multiply",
            }}
            opacity={0.35}
            fill="#ffd392"
            className="colorffb592 svgShape"
          />
          <path
            d="M181.86 129.4a3.75 3.75 0 00-1 2.19c-.12 1.38.78 2.56-.33 2.85a3.27 3.27 0 00-1.79.75c-1.09.73-1.61.57-2.7-1s-2.18-.7-2.26-3.11.23-3.54-1-4.36-3.32-1.35-3.35-2 1.63-5.4 5.06-6c0 0-4.38.32-6.15 1.58s-.85 2.87-2.56 3.45-4.18 1.08-5.84.36-3.69.56-4.46.58-3.13-.67-4.82.34-6.28 0-6.86-4.43 1.55-5.3 1.55-5.3-1.5-.82-.41-1.71 1.73-.06 1.73-.06-1.77-3.91 2.9-5.78 6.79-.66 6.79-.66a1.59 1.59 0 011.52-1 1.48 1.48 0 011.4.73s1.48-2.11 6.48-2.11 5.91 1.7 5.91 1.7 2-1.7 4.69 1.31a11.64 11.64 0 013 5.58s1.91.37 1.85 1.32a2.26 2.26 0 01-.47 1.39s1.82.12 2.69 3.19a11.55 11.55 0 01.11 5.77s.52.07.81 1-.6 3.58-.6 3.58-1.38-.55-1.89-.15z"
            fill="#0c2942"
            className="color0c1e42 svgShape"
          />
          <path
            d="M145.46 122.77s-1.66 1.87-1.07 6.59c.27 2.16 1.37 2.36 1.41 3.48a1.82 1.82 0 001.26 1.51s.56-8.81 1.4-9.69-3-1.89-3-1.89z"
            fill="#0c2942"
            className="color0c1e42 svgShape"
          />
          <path
            d="M173.88 307.28c-.49 6.71-1.48 14.54-1.48 14.54l-9.25.3c.69-.69 1.94-2.36 2.64-5.77 1-4.86-.81-6.86.38-9.2.53-1 1.6-2.72 2.63-4.44 1.43 3.11 4.64 2.83 5 4.24.02.05.05.19.08.33z"
            style={{
              mixBlendMode: "multiply",
            }}
            fill="#1d5d95"
            opacity={0.46}
            className="color1d4d95 svgShape"
          />
          <path
            d="M173.83 306.58c-.15-.05-3.72-1.38-5.31-3.68s-2-16.19-2-16.78h.68c.11 4 .67 14.65 1.89 16.41 1.47 2.12 4.95 3.41 5 3.43zm17.45-8.26c-2.93.1-5.38-.18-6.38-1.08-2.55-2.26-3.7-9.09-3.75-9.38l.68-.11c0 .07 1.16 6.87 3.53 9s14.08.5 19.94-1.54l.22.65a56.39 56.39 0 01-14.24 2.46zm-44.04-2.85a44.46 44.46 0 01-7.74-.65l.12-.68c2.53.45 9.47 1.29 11.19-.19 2.08-1.77 1.69-7.64 1.69-7.7l.69-.05c0 .26.41 6.27-1.93 8.28a7.12 7.12 0 01-4.02.99zm23.11-7.7a.93.93 0 11-.95-1.06 1 1 0 01.95 1.06z"
            fill="#ebf6ff"
            className="colorf0ebff svgShape"
          />
          <path
            d="M170.67 293.79a.93.93 0 11-1-1.06 1 1 0 011 1.06z"
            fill="#ebf6ff"
            className="colorf0ebff svgShape"
          />
          <path
            d="M184.71 253.13l16.88-3.55 1.29 4.32-17.81 3.35-.36-4.12zm-43.65-6.83l7.72 4.11-.57 3.47-7.93-3.64.78-3.94z"
            fill="#0c2942"
            className="color0c1e42 svgShape"
          />
          <path
            d="M204 281c-2.69 1-8.48 1.85-14.7 2.44a3.84 3.84 0 01-.11-.4s7.86-3.88 5.69-6.79-11.46.84-11.35-2.84 15.26-3.95 15.14-7.62-10.94-4.24-7.79-6.18 6.55-3.43 4.17-5.88-9.35-5-6.19-6.69 7.67-17.94 10.69-30.9c2.72-11.7 2.22-13.38 2.1-13.59a64.56 64.56 0 017.16 11.73c-.36 2.1-.77 4.6-1.26 7.57-1.34 8.08-1.63 20.26-1.69 25.32s1 3.64 1.59 6.38.47 7.33.55 9.63 2 5 3 8.4-.41 6.87-7 9.42zm23.47-89s-8.15 3.49-9.26 4.67 1.17-3.06 6.69-8.16c1.53 2.02 2.57 3.49 2.57 3.49zm.22-.31l-.22.29a.87.87 0 01.22-.29zm23.76 9.71l.85 25.82c-2.68 2.16-30 1.46-34.29-2.08a67.67 67.67 0 01-7.51-8 20.9 20.9 0 0015.1 1.54c9.11-2.37 7.57-7.37 15.52-10.16 3.96-1.38 7.63-4.42 10.33-7.12zm-115.39 13.21c-1.13.07-2.94 0-3.89-.86-1.42-1.33-1.92-2.69-3.47-.8s-7.19 4.83-7.78 7.84-7 6-10 8.48c-1.59 1.33-8.79 6.61-15.32 3.61-6-2.77-7.79-6.28-8.18-11.88 1.47 2.31 4.28 6 7.78 6.9 5.21 1.38 7.29-3.52 10-3.44s3.82-9.43 6.39-10 .48 4.29 2.89 4 5.45-6.9 8.12-9.74 2.9-.79 4.86-4.13 4.94-1.54 5 .52.83-1.06 1.45 2.2c.51 2.37 1.61 5.07 2.15 7.3zM111.51 198c-.08 6.65 0 11.16-.76 10.63s-2.89-.82-5-2.7.52-5 .82-9.79-1.76-4.54-3-7.14 1.79-4.77 3.47-9.65a15.12 15.12 0 00.34-8 8.74 8.74 0 012.52.89c1 .89.49 7.8.59 10.78s-.25 13.1-.45 14.26c-.17.97.35 1.94 1.47.72z"
            style={{
              mixBlendMode: "multiply",
            }}
            fill="#f39912"
            opacity={0.81}
            className="colorf95d6c svgShape"
          />
          <path
            d="M184.9 162.15s-.77.92-2.05 2.28a1.72 1.72 0 01-.4-.14c-1.52-.75-7.58-.09-9.47-1.52s-.31-2.29-2.08-3.95c-1.46-1.37-5-1-6.16-.88a8.41 8.41 0 002.47-1c4.28-1.84 10.88-5.37 12.32-8 .19-.35.36-.69.52-1 0 2.4 0 11.48.61 12.59.64 1.2 4.24 1.62 4.24 1.62zM164.33 158l.41-.06z"
            style={{
              mixBlendMode: "multiply",
            }}
            fill="#ffd392"
            opacity={0.72}
            className="colorffb592 svgShape"
          />
          <path
            d="M162.17 281.7c-1.5-14.29-.26-43.52-.25-43.82h.92c0 .29-1.25 29.46.24 43.69zm5.56-12.45a1.47 1.47 0 11-1.52-1.58 1.56 1.56 0 011.52 1.58zm-.61-23.41a1.47 1.47 0 11-2.92.1 1.47 1.47 0 112.92-.1z"
            fill="#0c2942"
            className="color0c1e42 svgShape"
          />
          <path
            d="M188 193.52l-6.6 1.83 2.42 3.59-20.62 36.29v-1.27c2.38-8 8.2-25 10.18-30.54 2.31-6.51 11.34-25.18 12.75-31a14.66 14.66 0 00.06-6.69c6.66 19.45 1.81 27.79 1.81 27.79zm-27.29 41.34V236s-14.4-25.48-15.7-30l-1.3-4.55 4.65-1.65-6-4.73s4.73-23.59 11-29.08a39.45 39.45 0 00-3.75 11.61c-1.04 6.1 5.46 43.96 11.1 57.26z"
            style={{
              mixBlendMode: "multiply",
            }}
            fill="#f39912"
            opacity={0.81}
            className="colorf95d6c svgShape"
          />
          <path
            d="M255.27 316.41l1 30.33a12.52 12.52 0 01-12.1 12.93l-147.46 4.9a12.54 12.54 0 01-12.93-12.11l-1-30.33z"
            fill="#d1e8fc"
            className="colore0d1fc svgShape"
          />
          <path
            d="M255.31 317.41l1 29.33a12.52 12.52 0 01-12.1 12.93l-147.46 4.9a12.37 12.37 0 01-7.4-2.13c7-5.22 22.17-15 36-13.68 18.71 1.84 52.55 3.53 64.57-5.66a102.37 102.37 0 0147.21-19.52 55 55 0 0018.18-6.17z"
            style={{
              mixBlendMode: "multiply",
            }}
            fill="#d1e8fc"
            className="colore0d1fc svgShape"
          />
          <path
            d="M246.79 60.72L74.3 66.44a12.52 12.52 0 0112.1-12.8l147.46-4.89a12.53 12.53 0 0112.93 11.97z"
            fill="#0c2942"
            className="color0c1e42 svgShape"
          />
          <path
            d="M236.18 94l-25.41.84-.83-25 25.41-.84zm-24.3-.35l23.12-.72-.75-22.76-23.11.77zm-100.15 4.52l-25.4.83-.84-25 25.41-.85zm-24.29-.35l23.11-.76-.76-22.76-23.11.76z"
            fill="#79afdd"
            className="color9d79dd svgShape"
          />
          <path
            d="M222.66 91.87l-16.3-10.88 3.93-5.89 11.07 7.39 12.05-14.17 5.39 4.59-16.14 18.96z"
            fill="#d1e8fc"
            className="colore0d1fc svgShape"
          />
          <path
            d="M91.42 85.21h15.65v1.15H91.42z"
            transform="rotate(-1.9 99.316 85.797)"
            fill="#79afdd"
            className="color9d79dd svgShape"
          />
          <path
            d="M96.04 89.91l-6.91-3.36 6.55-5.16.71.9-5.11 4.03 5.26 2.56-.5 1.03z"
            fill="#79afdd"
            className="color9d79dd svgShape"
          />
          <rect
            width={73.16}
            height={6.33}
            x={126.47}
            y={40.62}
            rx={1.94}
            transform="translate(-1.36 5.43)"
            fill="#fff"
            className="colorffffff svgShape"
          />
          <rect
            width={44.09}
            height={7.5}
            x={147.23}
            y={365.25}
            rx={2.17}
            transform="rotate(-1.9 169.42 368.966)"
            fill="#448cca"
            className="color2469ce svgShape"
          />
          <path
            d="M146.8 303.55h3.44v2.3h-3.44z"
            transform="rotate(-1.9 148.468 304.677)"
            fill="#d1e8fc"
            className="colore0d1fc svgShape"
          />
          <path
            d="M139.85 306.14l-7 .23-.08-2.29 7-.24zm-14 .46l-7 .23-.08-2.29 7-.23zm-14 .47l-7 .23-.08-2.3 7-.23zm-13.91.46l-4.59.15-.15-4.59 2.29-.08.08 2.3 2.29-.08.08 2.3zm-2.67-11.32l-2.3.08-.22-6.8 2.29-.08zm-.45-13.6l-2.3.08-.22-6.81 2.29-.07zM94.36 269l-2.29.07-.23-6.8 2.3-.08zm-.45-13.61l-2.29.08-.23-6.8 2.3-.08zm-.45-13.6l-2.29.07-.23-6.8 2.3-.08zm-.46-13.6l-2.29.08-.23-6.8 2.29-.08zm-.45-13.6l-2.3.07-.22-6.8 2.29-.07zM92.11 201l-2.3.08-.22-6.8 2.29-.08zm-.45-13.6l-2.3.08-.22-6.81 2.29-.07zm-.46-13.6l-2.29.07-.23-6.8L91 167zm-.45-13.61l-2.29.08-.23-6.8 2.3-.08zm-.45-13.6l-2.29.07-.23-6.8 2.3-.07zM89.85 133l-2.29.08-.23-6.8 2.29-.08zm-.45-13.64l-2.3.08-.15-4.59 4.59-.16.08 2.3-2.3.07.08 2.3zm31.21-3.36l-7.25.24-.08-2.3 7.25-.24zm-14.5.48l-7.24.24-.08-2.3 7.25-.24z"
            fill="#d1e8fc"
            className="colore0d1fc svgShape"
          />
          <path
            d="M127.82 113.43h3.44v2.3h-3.44z"
            transform="rotate(-1.9 129.602 114.65)"
            fill="#d1e8fc"
            className="colore0d1fc svgShape"
          />
          <path
            d="M181.65 111.65h3.44v2.3h-3.44z"
            transform="rotate(-1.9 183.319 112.827)"
            fill="#d1e8fc"
            className="colore0d1fc svgShape"
          />
          <path
            d="M227 112.5l-7 .23-.07-2.3 7-.23zm-14 .46l-7 .23v-2.29l7-.24zm-14 .46l-7 .23-.07-2.29 7-.23zm39.69.99l-2.29.07-.08-2.29-2.3.07-.07-2.29 4.59-.15.15 4.59zm5.87 176.85l-2.3.08-.22-6.81 2.29-.07zm-.45-13.6l-2.3.07-.22-6.8 2.29-.08zm-.45-13.61l-2.3.08-.22-6.8 2.29-.08zm-.46-13.6l-2.29.07-.23-6.8 2.3-.08zm-.45-13.61l-2.29.08-.23-6.8 2.3-.08zm-.45-13.6l-2.29.07-.23-6.8 2.3-.07zm-.45-13.61l-2.29.08-.23-6.8 2.29-.08zM241.4 196l-2.3.08-.22-6.81 2.29-.07zm-.45-13.6l-2.3.07-.22-6.8 2.29-.08zm-.45-13.61l-2.3.08-.22-6.8 2.29-.08zm-.46-13.6l-2.29.07-.23-6.8 2.3-.07zm-.45-13.61l-2.29.08-.23-6.8 2.3-.08zm-.45-13.6l-2.29.08-.23-6.81 2.3-.07zm5.8 174.67l-4.59.15-.08-2.29 2.3-.08-.08-2.29 2.29-.08.16 4.59zm-11.84.35l-7.25.24-.07-2.29 7.24-.24zm-14.5.48l-7.24.25-.08-2.3 7.25-.24z"
            fill="#d1e8fc"
            className="colore0d1fc svgShape"
          />
          <path
            d="M200.63 301.77h3.44v2.3h-3.44z"
            transform="rotate(-1.9 202.185 302.853)"
            fill="#d1e8fc"
            className="colore0d1fc svgShape"
          />
          <path
            d="M112.54 342.29a10.32 10.32 0 11-10.65-10 10.32 10.32 0 0110.65 10z"
            fill="#4f91c9"
            className="color7b4fc9 svgShape"
          />
          <path
            d="M98.92 346.7l2.92-.09-.26-8.07-2.94.1v-.86a11.74 11.74 0 003.79-1.33h.56l.34 10.13 2.71-.09v1.2l-7.09.24z"
            fill="#d1e8fc"
            className="colore0d1fc svgShape"
          />
          <path
            d="M142.42 341.3a10.32 10.32 0 11-10.65-10 10.3 10.3 0 0110.65 10z"
            fill="#4f91c9"
            className="color7b4fc9 svgShape"
          />
          <path
            d="M128.7 346.42a3.08 3.08 0 01.41-1.66 5.5 5.5 0 011.07-1.36c.43-.4 1-.87 1.7-1.42s1-.83 1.39-1.15a5.86 5.86 0 00.87-1 2.47 2.47 0 00.51-1.54 2 2 0 00-.53-1.33 2.16 2.16 0 00-1.71-.52 2.41 2.41 0 00-1.8.67 2.12 2.12 0 00-.52 1.58v.68h-1.46a5.15 5.15 0 01-.07-.66 3 3 0 011-2.61 4.5 4.5 0 012.83-.95 3.92 3.92 0 012.64.73 3.39 3.39 0 01.63 4.14 6.81 6.81 0 01-1.15 1.43 22.25 22.25 0 01-1.78 1.49c-.54.42-1 .76-1.26 1a6.62 6.62 0 00-.79.81 2.36 2.36 0 00-.37.59l6.09-.2v1.39l-7.78.25z"
            fill="#d1e8fc"
            className="colore0d1fc svgShape"
          />
          <circle
            cx={170.48}
            cy={340.14}
            r={18.76}
            transform="rotate(-12.94 170.542 340.243)"
            fill="#4f91c9"
            className="color7b4fc9 svgShape"
          />
          <path
            d="M171 355.89a15.76 15.76 0 1115.22-16.27A15.76 15.76 0 01171 355.89zM170 326a14.15 14.15 0 1014.61 13.67A14.17 14.17 0 00170 326z"
            fill="#d1e8fc"
            className="colore0d1fc svgShape"
          />
          <path
            d="M167.87 345.06a3.09 3.09 0 01-1.14-2.45v-.13l1.47-.05v.18a2 2 0 00.7 1.57 2.7 2.7 0 001.83.5 3.05 3.05 0 001.88-.6 1.91 1.91 0 00.63-1.62 1.71 1.71 0 00-.79-1.54 3.51 3.51 0 00-1.92-.39h-1.19v-1.21h1.24a2.71 2.71 0 001.65-.56 1.75 1.75 0 00.63-1.5 1.74 1.74 0 00-.69-1.46 2.5 2.5 0 00-1.62-.45 2.6 2.6 0 00-1.66.58 1.87 1.87 0 00-.57 1.55v.15l-1.47.05v-.3a2.75 2.75 0 01.43-1.64 3.13 3.13 0 011.29-1.14 4.53 4.53 0 011.93-.45 4.28 4.28 0 012.77.72 2.74 2.74 0 011.1 2.26 2.33 2.33 0 01-.45 1.56 3.69 3.69 0 01-1.35 1.05v.06a3 3 0 011.59.94 2.78 2.78 0 01.64 1.77 3 3 0 01-.45 1.74 3.19 3.19 0 01-1.36 1.17 5.19 5.19 0 01-2 .46 4.43 4.43 0 01-3.12-.82z"
            fill="#d1e8fc"
            className="colore0d1fc svgShape"
          />
          <circle
            cx={207.34}
            cy={339.15}
            r={10.32}
            transform="rotate(-46.9 207.343 339.145)"
            fill="#4f91c9"
            className="color7b4fc9 svgShape"
          />
          <path
            d="M208.43 341.7l-5.27.17v-1.22a30.18 30.18 0 001.72-3.73 23.46 23.46 0 001.07-3.89h1.57a19.57 19.57 0 01-.82 3c-.38 1.06-.76 2-1.16 2.86a12.39 12.39 0 01-.95 1.79l3.84-.13-.12-3.72c.21-.6.41-1.32.61-2.15h.77l.2 5.85 1.5-.05v1.21h-1.5l.09 2.79-1.45.05z"
            fill="#d1e8fc"
            className="colore0d1fc svgShape"
          />
          <path
            d="M247.54 337.81a10.32 10.32 0 11-10.66-10 10.33 10.33 0 0110.66 10z"
            fill="#4f91c9"
            className="color7b4fc9 svgShape"
          />
          <path
            d="M234.25 342.64a3.55 3.55 0 01-1.16-2.69h1.49a2.38 2.38 0 002.56 2.44 2.31 2.31 0 001.74-.74 2.8 2.8 0 00.6-2.05 2.51 2.51 0 00-.69-1.78 2.26 2.26 0 00-1.69-.59 2.84 2.84 0 00-1.37.4 2.58 2.58 0 00-.95 1l-1.35-.18.26-6.29 6.44-.21.05 1.39-5.24.17-.15 3.46a4 4 0 012.57-1 3.51 3.51 0 012.55.86 3.61 3.61 0 011.07 2.69 3.88 3.88 0 01-.95 2.91 4 4 0 01-2.85 1.12 4.11 4.11 0 01-2.93-.91z"
            fill="#d1e8fc"
            className="colore0d1fc svgShape"
          />
          <path
            d="M345.33 491.58a65.34 65.34 0 01-12.48 22.47l-.35.39c-11.95 13.22-28.82 17.19-47.85 7.49-27.31-13.93-54.65-40.6-65.48-74.48-.09-.28-.17-.55-.26-.82-10.67-34.27-19.66-47.19-23.59-52.25s-23.6-28.09-29.78-31.46-44.39-31.46-45.54-52.82a26.11 26.11 0 011-9c3.88-12.67 17.58-13.45 17.58-13.45a39.27 39.27 0 0112.87 2c29.21 9 70.47 44.87 78.71 53.59 9.55 10.12 44.39 16.86 58.43 28.1 7.31 5.84 25.08 30 39.05 56.14 12.89 24.11 22.55 49.81 17.69 64.1z"
            fill="#ffd392"
            className="colorffb592 svgShape"
          />
          <path
            d="M332.85 514.05l-.35.39c-11.95 13.22-28.82 17.19-47.85 7.49-27.31-13.93-54.65-40.6-65.48-74.48-.09-.28-.17-.55-.26-.82-10.67-34.27-19.66-47.19-23.59-52.25s-23.6-28.09-29.78-31.46-44.39-31.46-45.54-52.82a26.11 26.11 0 011-9c4 8.74 19.88 40.69 44.55 48.92 28.65 9.55 23 12.92 29.21 23s37.65 23 53.38 53.38c14.63 28.23 53.57 79.31 84.71 87.65z"
            style={{
              mixBlendMode: "multiply",
            }}
            fill="#ffd392"
            opacity={0.33}
            className="colorffb592 svgShape"
          />
          <path
            d="M154.86 299.71s1.89 5.91-11.23 21.35c-4.78 5.62-12.25 6.29-14.34 5.07-7.37-4.3-9.3-18.67-6.45-25 3.65-8.15 10.67-10.12 16-10.12s13.21 4.49 16.02 8.7z"
            fill="#ffebcc"
            className="colorfff2cc svgShape"
          />
        </g>
      </g>
    </svg>
  );
};
